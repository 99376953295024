import React from 'react';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AITextToText } from '../../utilities/AI';
import AudioRecorder from '../../utilities/AudioRecorder';


const AIInstruction = `
You are a medical practitioner.

You will receive two types of data:
1. 'input': A text containing a query.
2. 'initData': A JSON array of objects, each containing patient data and medical records.

### Task:

1. Read and understand the query provided in 'input'.
2. Find the relevant information in 'initData' to answer the query.
3. Present the response as a concise and relevant text answer.
4. Do not provide any information outside of what is available in 'initData'.
5. Use British spelling.
6. Use the date format dd-mm-yyyy for any dates mentioned in the content, converting them if necessary.
7. You may use appropriate medical terminology where relevant.
8. Do not use bullet points or number points.
`;



class ChatRecord extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //AI section
            isLoading: false,
            AInote: "",
            chats: []

        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

        // Ref for the menu container
        this.menuRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if formData has changed and if it's not empty, then save to localStorage
        if (prevState.formData !== this.state.formData && Object.keys(this.state.formData).length !== 0) {
            this.saveToLocalStorage();
        }

        if (prevState.AInote !== this.state.AInote) {
            this.renderOperational();
        }
    }

    AITranscription = async (newTranscription, toastId) => {

        var chats = this.state.chats;
        chats.push({role: 'You', content: newTranscription});
        // Agent to write notes, then add to consultation box
        toast.update(toastId, {
            render: 'Reading records ...',
            autoClose: false,
            hideProgressBar: false
        });

        const respond = await AITextToText(AIInstruction, newTranscription, this.props.patientData);
        
        this.setState({ AInote: respond });
        chats.push({role:'Caredevo', content: respond})

        this.setState({chats: chats});

        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });

    };


    // rendering section
    renderOperational() {
        const { AInote, chats } = this.state;
        
        var messages;
        if (AInote) {
            messages = AInote;
        } else {
            messages = ""
        }
        return (
   
       
            <div className="col-span-6 bg-white p-6 mt-20t" >
            <div className='col-span-6'>  <AudioRecorder onInputUpdate={this.AITranscription} /></div>
            <div className=' m-10 z-10 xs:m-0' ref={this.drawerRef}>
                           

                <div className='m-4'>
                    <p className='text-md font-semibold mx-4 text-center'>Chat Records</p>
                    <div className='w-full flex flex-col items-center my-4'>
                    {chats.map(chat => (
                        chat.role === "You" ? (
                            <div className='bg-slate-200 w-[800px] -ml-[600px] p-2 m-4 rounded shadow' key={chat.id}>
                                <div className='m-4 text-md'>
                                    You : 
                                    <p></p>
                                    {chat.content} 
                                </div>
                            </div>
                        ) : (
                            <div className='bg-slate-100 w-[800px] ml-[600px] p-2 m-4 rounded shadow' key={chat.id}>
                                <div className='m-4 text-md italic'>
                                    Caredevo : 
                                    <p></p>
                                    {chat.content} 
                                </div>
     
                            </div>
                        )
                    ))}
                    </div>


               
                </div>
            </div>
            </div>
      

        
        );
    }

    render() {

      
        return (
            <div className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: 'minmax(320px, auto)' }}>
              
                {this.renderOperational()}
                <ToastContainer />
            </div>
        );
    }
    
}

export default ChatRecord;
