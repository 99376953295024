import React from 'react';
import CryptoJS from 'crypto-js';

const professionOptions = [
    "General Practitioner (GP)",
    "Pediatrician",
    "Cardiologist",
    "Dermatologist",
    "Neurologist",
    "Oncologist",
    "Ophthalmologist",
    "Orthopedic Surgeon",
    "Psychiatrist",
    "Radiologist",
    "Anesthesiologist",
    "Endocrinologist",
    "Gastroenterologist",
    "Hematologist",
    "Infectious Disease Specialist",
    "Nephrologist",
    "Obstetrician/Gynecologist (OB/GYN)",
    "Pulmonologist",
    "Rheumatologist",
    "Urologist",
    "Surgeon",
    "Plastic Surgeon",
    "Emergency Medicine Physician",
    "Pathologist",
    "Otolaryngologist (ENT)",
    "Allergy and Immunology Specialist",
    "Critical Care Medicine Specialist",
    "Geriatrician",
    "Geneticist",
    "Sports Medicine Specialist",
    "Podiatrist",
    "Occupational Medicine Specialist",
    "Physiatrist (Physical Medicine and Rehabilitation)",
    "Family Medicine Physician",
    "Hospitalist",
    "Medical Geneticist",
    "Pain Management Specialist",
    "Sleep Medicine Specialist",
    "Nuclear Medicine Specialist",
    "Preventive Medicine Specialist",
    "Physiotherapist",
    "Occupational Therapist",
    "Speech Pathologist",
    "Dietitian",
    "Pharmacist",
    "Audiologist",
    "Social Worker",
    "Chiropractor",
    "Exercise Physiologist",
    "Podiatrist",
    "Prosthetist/Orthotist",
    "Medical Laboratory Scientist",
    "Medical Imaging Technologist",
    "Psychologist",
    "Clinical Psychologist",
    "Counsellor",
    "Mental Health Nurse",
    "Case Manager",
    "Medical Power of Attorney (MPOA)",
    "Care Worker",
    "Aged Care Worker",
    "Disability Support Worker",
    "Home Care Assistant",
    "Personal Care Assistant (PCA)"
];

const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

class PractitionerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            formData: {
                _id: '',
                title: '',
                firstName: '',
                lastName: '',
                profession: '',
                practiceName: '',
                providerNumber: '',
                address1: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                phone: '',
                fax: '',
                email: ''
            },
            contacts: []
        };

        this.handleClear = this.handleClear.bind(this);
        this.handleSaveOrUpdate = this.handleSaveOrUpdate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
  
    componentDidMount() {
        const encryptedAdmin = localStorage.getItem('admin');
        if (encryptedAdmin) {
            const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
            const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const savedContacts = adminData.practices;
            this.setState({ contacts: savedContacts });
        }
        this.setState({ formData: { ...this.props.data } });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ formData: { ...this.props.data } });
        }
    }

    handleChange = (event) => {
        this.setState({ editing: true });
        const { name, value, type, checked } = event.target;
    
        this.setState(prevState => {
            let formDataUpdate = {};
    
            if (type === 'checkbox') {
                formDataUpdate[name] = checked;
            } else {
                formDataUpdate[name] = value;
            }
    
            return { formData: { ...prevState.formData, ...formDataUpdate } };
        });
    };
    
    handleSaveOrUpdate = () => {
        const { formData } = this.state;
        this.props.onSave(formData);
        this.setState({ editing: false });
        this.handleClear();
    };

    handleDelete = () => {
        const { formData } = this.state;
        if (formData._id) {
            this.props.onDelete(formData._id);
        }
        this.setState({ editing: false });
        this.handleClear();
        this.props.onCancel(); 
    };

    handleClear() {
        this.setState({
            formData: {
                _id: '',
                title: '',
                firstName: '',
                lastName: '',
                profession: '',
                practiceName: '',
                providerNumber: '',
                address1: '',
                address2: '',
                suburb: '',
                postcode: '',
                state: '',
                phone: '',
                fax: '',
                email: ''
            },
        });
        this.setState({ editing: false });
    }

    handleCancel = () => {
        this.props.onCancel();
        this.setState({ editing: false });
    }

    handleSelect(contact) {
        const { _id, ...contactWithoutId } = contact; // Destructure to exclude _id
        this.setState({ formData: { ...contactWithoutId }, editing: true });
    }

    renderForm() {
        const { formData } = this.state;
        const titles = ['Ms', 'Mrs', 'Mr', 'A/Prof', 'Prof', 'Dr'];
        const australianStates = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];

        return (
            <div className='h-[240px] p-4'>
                <form>  
                    <p className='text-xs m-2'>Please enter the details of the practitioner you wish to add to the patient's care team.</p>
                    <div className='grid grid-cols-[1fr,1fr] p-6'>
                        <label className='text-xs m-2 p-1'>Title</label>
                        <select
                            name="title"
                            value={formData.title}
                            onChange={this.handleChange}
                            className='border border-slate-200 p-1 m-2 text-xs'
                        >
                            <option value="">Select Title</option>
                            {titles.map((title) => (
                                <option key={title} value={title}>
                                    {title}
                                </option>
                            ))}
                        </select>
                        <label className='text-xs m-2 p-1'>First Name</label>
                        <input 
                            type="text" 
                            required 
                            name="firstName" 
                            value={formData.firstName} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Last Name</label>
                        <input 
                            type="text" 
                            required 
                            name="lastName" 
                            value={formData.lastName} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Profession</label>
                        <input 
                            type="text" 
                            required 
                            name="profession" 
                            value={formData.profession} 
                            onChange={this.handleChange} 
                            list="professionList"
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                            <datalist id="professionList">
                                {professionOptions.map((profession, index) => (
                                    <option key={index} value={profession} />
                                ))}
                            </datalist>
                        <label className='text-xs m-2 p-1'>Practice Name</label>
                        <input 
                            type="text" 
                            name="practiceName" 
                            value={formData.practiceName} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Provider Number</label>
                        <input 
                            type="text" 
                            name="providerNumber" 
                            value={formData.providerNumber} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Address 1</label>
                        <input 
                            type="text" 
                            required 
                            name="address1" 
                            value={formData.address1} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Address 2</label>
                        <input 
                            type="text" 
                            name="address2" 
                            value={formData.address2} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Suburb</label>
                        <input 
                            type="text" 
                            required 
                            name="suburb" 
                            value={formData.suburb} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>State</label>
                        <select
                            name="state"
                            value={formData.state}
                            onChange={this.handleChange}
                            className='border border-slate-200 p-1 m-2 text-xs'
                        >
                            <option value="">Select State</option>
                            {australianStates.map((state) => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                        </select>
                        <label className='text-xs m-2 p-1'>Post code</label>
                        <input 
                            type="text" 
                            required 
                            name="postcode" 
                            value={formData.postcode} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Phone</label>
                        <input 
                            type="text" 
                            required 
                            name="phone" 
                            value={formData.phone} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Fax</label>
                        <input 
                            type="text" 
                            name="fax" 
                            value={formData.fax} 
                            onChange={this.handleChange} 
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                        <label className='text-xs m-2 p-1'>Email address</label>
                        <input 
                            type="email" 
                            required 
                            name="email" 
                            value={formData.email} 
                            onChange={this.handleChange} 
                            onInvalid={e => e.target.setCustomValidity('Please enter a valid email address.')} 
                            onInput={e => e.target.setCustomValidity('')}
                            className='border border-slate-200 p-1 m-2 text-xs'
                        />
                    </div>
                </form>
            </div>
        )
    }

    renderContacts() {
        return (
            <div className='overflow-y-auto'>
              <h2 className='text-lg font-bold mb-2'>Saved Contacts</h2>
              <ul>
                {this.state.contacts.map(contact => (
                  <li key={contact._id} className='border-b py-2 flex justify-between items-center'>
                    <div>
                      <p className='font-bold'>{contact.title} {contact.firstName} {contact.lastName}</p>
                      <p>{contact.profession}</p>
                      <p>{contact.practiceName}</p>
                      <p>{contact.address1}, {contact.address2}, {contact.suburb}, {contact.postcode}, {contact.state}</p>
                      <p>Phone: {contact.phone}, Fax: {contact.fax}</p>
                      <p>Email: {contact.email}</p>
                    </div>
                    <div>
                      <button className='bg-sky-600 hover:bg-sky-700 text-white py-1 px-3 rounded' onClick={() => this.handleSelect(contact)}>Select</button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
        )
    }

    render() {
        return (
            <div id={this.state.popUpId}   
                style={{
                    ...this.props.style,
                    display: this.props.style.display === undefined ? 'none' : this.props.style.display,
                }} 
                className="popup fixed left-0 top-0 h-full w-full z-50 bg-slate-900/80"
            >
                <div className='flex justify-center items-center h-screen'>
                    <div className='w-[1200px] h-[800px] bg-white rounded shadow-sm relative' style={{ gridTemplateRows: '100px auto 200px' }}>
                        <div className='py-3 px-4 font-semibold text-lg text-center'>Add Practitioner</div>

                        <div className='grid grid-cols-2 gap-10'>
                            <div>
                                <div className=''>{this.renderForm()}</div>
                                <div className='flex absolute bottom-2 w-full justify-center'>
                                    <button className='text-white py-1 mx-2 px-2 my-2 w-20 rounded-sm shadow-sm bg-red-700 hover:bg-red-800 bar:text-xs bar:w-16' onClick={this.handleCancel}>Cancel</button>
                                    <button className='text-white py-1 mx-2 px-2 my-2 w-20 rounded-sm shadow-sm bg-yellow-700 hover:bg-yellow-800 bar:text-xs bar:w-16' onClick={this.handleDelete}>Delete</button>
                                    <button className='text-white py-1 mx-2 px-2 my-2 w-20 rounded-sm shadow-sm bg-sky-700 hover:bg-sky-800 bar:text-xs bar:w-16' onClick={this.handleSaveOrUpdate}>{this.props.data._id ? 'Update' : 'Add'}</button>
                                </div>  
                            </div>
                            <div className='p-10'>
                                {this.renderContacts()}
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        )
    }
}

export default PractitionerForm;
