import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HomeIcon, ChatBubbleLeftEllipsisIcon, UserPlusIcon, Cog6ToothIcon, UserIcon, LightBulbIcon, MicrophoneIcon, RectangleGroupIcon, ShieldCheckIcon, HeartIcon, BriefcaseIcon, FaceSmileIcon, NewspaperIcon, UserGroupIcon, DocumentTextIcon, ChatBubbleLeftIcon } from '@heroicons/react/20/solid';

import { useAuth } from '../../utilities/AuthContext';

const Sidebar = (props) => {
    const navigate = useNavigate();
    const [auth] = useAuth(); 


    return (
        <div className='fixed top-0 left-0 w-10 h-full bg-slate-600 shadow hover:w-48 z-10 overflow-hidden transition-all duration-300'>
            <nav className="text-white text-sm mt-20">
                <ul>
                    {auth.isLoggedIn ? (
                        <>
                    
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Consultation")}>
                                    <div><MicrophoneIcon className='w-6 h-6'  /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Consultation</div>
                                </div>
                            </li> 
    
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("General")}>
                                    <div><DocumentTextIcon className='w-6 h-6'  /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>General</div>
                                </div>
                            </li> 
    
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Mental Health")}>
                                    <div><FaceSmileIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Mental Health</div>
                                </div>
                            </li>
    
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Health Assessment")}>
                                    <div><ShieldCheckIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Health Assessment</div>
                                </div>
                            </li>
    
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("CDM")}>
                                    <div><HeartIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Chronic Disease</div>
                                </div>
                            </li>
    
                            {/* <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => navigate('/allplan')}>
                                    <div><BriefcaseIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>All Plans</div>
                                </div>
                            </li> */}
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Chat")}>
                                    <div><ChatBubbleLeftEllipsisIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Chat</div>
                                </div>
                            </li>
                            <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                                <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Settings")}>
                                    <div><Cog6ToothIcon className='w-6 h-6' /></div>
                                    <div className='p-1 ml-2 whitespace-nowrap'>Settings</div>
                                </div>
                            </li>

                        </>
                    ) : (
                        <li className="mb-6 hover:bg-gray-700 p-2 mr-2 rounded flex items-center h-10">
                            <div className='flex flex-row cursor-pointer items-center' onClick={() => props.component("Home")}>
                                <div><HomeIcon className='w-6 h-6' /></div>
                                <div className='p-1 ml-2 whitespace-nowrap'>Home</div>
                            </div>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
    
};

export default Sidebar;

