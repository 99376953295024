import React, { Component } from 'react';
import SmartPaste from '../../utilities/SmartPaste';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToContact } from '../../utilities/ContactsAI';

// Helper function to generate unique IDs
function generateId() {
  return Math.random().toString(36).substr(2, 9);
}

// Constants for dropdown options
const titles = ['Mr', 'Mrs', 'Ms', 'Dr'];
const australianStates = ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT'];
const professionOptions = [
  "General Practitioner (GP)",
  "Pediatrician",
  "Cardiologist",
  "Dermatologist",
  "Neurologist",
  "Oncologist",
  "Ophthalmologist",
  "Orthopedic Surgeon",
  "Psychiatrist",
  "Radiologist",
  "Anesthesiologist",
  "Endocrinologist",
  "Gastroenterologist",
  "Hematologist",
  "Infectious Disease Specialist",
  "Nephrologist",
  "Obstetrician/Gynecologist (OB/GYN)",
  "Pulmonologist",
  "Rheumatologist",
  "Urologist",
  "Surgeon",
  "Plastic Surgeon",
  "Emergency Medicine Physician",
  "Pathologist",
  "Otolaryngologist (ENT)",
  "Allergy and Immunology Specialist",
  "Critical Care Medicine Specialist",
  "Geriatrician",
  "Geneticist",
  "Sports Medicine Specialist",
  "Podiatrist",
  "Occupational Medicine Specialist",
  "Physiatrist (Physical Medicine and Rehabilitation)",
  "Family Medicine Physician",
  "Hospitalist",
  "Medical Geneticist",
  "Pain Management Specialist",
  "Sleep Medicine Specialist",
  "Nuclear Medicine Specialist",
  "Preventive Medicine Specialist",
  "Physiotherapist",
  "Occupational Therapist",
  "Speech Pathologist",
  "Dietitian",
  "Diabetes Educator",
  "Pharmacist",
  "Audiologist",
  "Social Worker",
  "Chiropractor",
  "Osteopath",
  "Exercise Physiologist",
  "Prosthetist/Orthotist",
  "Medical Laboratory Scientist",
  "Medical Imaging Technologist",
  "Psychologist",
  "Clinical Psychologist",
  "Counsellor",
  "Mental Health Nurse",
  "Case Manager",
  "Medical Power of Attorney (MPOA)",
  "Care Worker",
  "Aged Care Worker",
  "Disability Support Worker",
  "Home Care Assistant",
  "Personal Care Assistant (PCA)"
];

const sampleContacts = `
Dr. Angela Black
Profession: Clinical Psychologist
Provider Number: 5678901
Address: 33 Mind St
Suburb: Perth
State: WA
Postcode: 6000
Phone: (08) 9123 4567
Fax: (08) 9123 4568
Email: angela.black@mentalhealthcare.com

Mr. David Thompson
Profession: General Practitioner (GP)
Provider Number: 6789012
Address: 90 Health Ave
Suburb: Darwin
State: NT
Postcode: 0800
Phone: (08) 8932 1234
Fax: (08) 8932 1235
Email: david.thompson@localgp.com.au

Mrs. Lucy Lee
Profession: Speech Pathologist
Provider Number: 7890123
Address: 22 Speech Lane
Address Line 2: Level 3, Unit 15
Suburb: Hobart
State: TAS
Postcode: 7000
Phone: (03) 6234 7890
Fax: (03) 6234 7891
Email: lucy.lee@talktherapy.com
`

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contacts: props.data || [], // Initialize with passed data
      newContact: this.resetNewContact(), // Form state for a new contact
      editingContactId: null, // ID of the contact currently being edited
    };
  }

  componentDidMount() {
    // Load practices from localStorage on mount
    const adminData = this.props.loadFromLocalStorage();
    if (adminData) {
      this.setState({ contacts: adminData.contacts || [] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      // Update state when props change
      this.setState({
        contacts: this.props.data || [],
        newContact: this.resetNewContact(), // Reset form when data changes
        editingContactId: null, // Reset editing state
      });
    }
  }
  
  /**
   * Reset the form fields to default values for adding a new contact.
   * @returns {object} - Object representing the default state of the form.
   */
  resetNewContact = () => ({
    id: '',
    title: '',
    firstName: '',
    lastName: '',
    profession: '',
    providerNumber: '',
    address1: '',
    address2: '',
    suburb: '',
    postcode: '',
    state: '',
    phone: '',
    fax: '',
    email: ''
  });

  /**
   * Add or update a contact. Updates the file system and localStorage after every change.
   */
  handleSubmit = () => {
    const { contacts, newContact } = this.state;
    const existingContact = contacts.find(contact => contact.id === newContact.id);
  
    if (existingContact) {
      this.setState(prevState => ({
        contacts: prevState.contacts.map(contact =>
          contact.id === existingContact.id ? { ...contact, ...prevState.newContact } : contact
        ),
        newContact: this.resetNewContact(),
        editingContactId: null
      }), () => {
        this.props.saveToFileSystem({ contacts: this.state.contacts });
        this.props.saveToLocalStorage({ contacts: this.state.contacts });
      });
    } else {
      this.setState(prevState => ({
        contacts: [...prevState.contacts, { ...prevState.newContact, id: generateId() }],
        newContact: this.resetNewContact(),
        editingContactId: null
      }), () => {
        this.props.saveToFileSystem({ contacts: this.state.contacts });
        this.props.saveToLocalStorage({ contacts: this.state.contacts });
      });
    }
  };

  /**
   * Edit an existing contact, filling the form with the selected contact's data.
   * @param {object} contact - The contact data to edit.
   */
  handleEdit = (contact) => {
    this.setState({
      newContact: { ...contact },
      editingContactId: contact.id
    });
  };

  /**
   * Delete a contact by its ID. Saves the updated contacts to the file system and localStorage.
   * @param {number} id - The ID of the contact to delete.
   */
  handleDelete = (id) => {
    this.setState(prevState => ({
      contacts: prevState.contacts.filter(contact => contact.id !== id)
    }), () => {
      this.props.saveToFileSystem({ contacts: this.state.contacts });
      this.props.saveToLocalStorage({ contacts: this.state.contacts });
    });
  };

  /**
   * Handle changes to form inputs and update the form state.
   * @param {object} e - The input change event.
   */
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      newContact: {
        ...prevState.newContact,
        [name]: value
      }
    }));
  };

  AIPaste = async (text, toastId) => {
    // filling up form
    toast.update(toastId, {
       render: 'Reading text..',
       autoClose: false,
       hideProgressBar: false
   });

   var respond = await textToContact(text);

   // updating localStorage

   if (respond) {
       const combinedContacts = [...this.state.contacts, ...respond];

       this.setState({ contacts: combinedContacts }, () => {
           // Now this.state.referrences is updated, and you can safely call your functions
           this.props.saveToFileSystem({ referrences: this.state.referrences });
           this.props.saveToLocalStorage({ referrences: this.state.referrences });
       });
   }

   // all done
   toast.update(toastId, {
       render: 'All task done!',
       type: toast.TYPE.SUCCESS,
       autoClose: 1000,  
       hideProgressBar: false
   });

}

  render() {
    const { contacts, newContact, editingContactId } = this.state;

    return (
      <div>
        <p className="text-xl text-center font-bold m-10">Contact Manager</p>
        <div><SmartPaste onPasteData={this.AIPaste}/></div>

        {/* Form for adding/editing a contact */}
        <div className="flex justify-center">
          <div className="p-4 w-[1200px] grid grid-cols-2 gap-10 bg-slate-100 m-10 rounded shadow">
            <div className="mb-4">
              <h2 className="text-xl mb-2 bg-sky-800 text-white p-4 text-center rounded">
                {editingContactId ? 'Edit Contact' : 'Add New Contact'}
              </h2>
              <div className="grid grid-cols-[1fr_2fr] gap-4 p-4">
                {/* Contact form fields */}
                <label className="p-1 text-right">Title</label>
                <select
                  name="title"
                  value={newContact.title}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                >
                  <option value="">Select Title</option>
                  {titles.map((title) => (
                    <option key={title} value={title}>
                      {title}
                    </option>
                  ))}
                </select>

                <label className="p-1 text-right">First Name</label>
                <input
                  type="text"
                  required
                  name="firstName"
                  value={newContact.firstName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Last Name</label>
                <input
                  type="text"
                  required
                  name="lastName"
                  value={newContact.lastName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Profession</label>
                <input
                  type="text"
                  required
                  name="profession"
                  value={newContact.profession}
                  onChange={this.handleChange}
                  list="professionList"
                  className="border border-slate-200 p-1"
                />
                <datalist id="professionList">
                  {professionOptions.map((profession, index) => (
                    <option key={index} value={profession} />
                  ))}
                </datalist>

                <label className="p-1 text-right">Provider Number</label>
                <input
                  type="text"
                  name="providerNumber"
                  value={newContact.providerNumber}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Practice Name</label>
                <input
                  type="text"
                  name="contactName"
                  value={newContact.contactName}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Address 1</label>
                <input
                  type="text"
                  required
                  name="address1"
                  value={newContact.address1}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Address 2</label>
                <input
                  type="text"
                  name="address2"
                  value={newContact.address2}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Suburb</label>
                <input
                  type="text"
                  required
                  name="suburb"
                  value={newContact.suburb}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">State</label>
                <select
                  name="state"
                  value={newContact.state}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                >
                  <option value="">Select State</option>
                  {australianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>

                <label className="p-1 text-right">Postcode</label>
                <input
                  type="text"
                  required
                  name="postcode"
                  value={newContact.postcode}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Phone</label>
                <input
                  type="text"
                  required
                  name="phone"
                  value={newContact.phone}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Fax</label>
                <input
                  type="text"
                  name="fax"
                  value={newContact.fax}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />

                <label className="p-1 text-right">Email address</label>
                <input
                  type="email"
                  required
                  name="email"
                  value={newContact.email}
                  onChange={this.handleChange}
                  className="border border-slate-200 p-1"
                />
              </div>

              <div className="flex justify-center">
                <button
                  className="bg-sky-600 hover:bg-sky-700 text-white py-2 px-4 rounded w-40"
                  onClick={this.handleSubmit}
                >
                  {editingContactId ? 'Update Contact' : 'Add Contact'}
                </button>
              </div>
            </div>

            {/* List of contacts */}
            <div>
              <h2 className="text-xl mb-2 bg-sky-800 text-white p-4 text-center rounded">
                Contacts List
              </h2>
              <ul>
                {contacts.map((contact) => (
                  <li key={contact.id} className="border-b py-2 flex justify-between items-center">
                    <div>
                      <p className="font-bold">
                        {contact.title} {contact.firstName} {contact.lastName}
                      </p>
                      <p>{contact.profession}</p>
                      <p>{contact.address1} {contact.address2}</p>
                      <p>{contact.suburb}, {contact.postcode}, {contact.state}</p>
                      <p>Phone: {contact.phone}, Fax: {contact.fax}</p>
                      <p>Email: {contact.email}</p>
                    </div>
                    <div>
                      <button
                        className="bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-3 rounded mr-2"
                        onClick={() => this.handleEdit(contact)}
                      >
                        Edit
                      </button>
                      <button
                        className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                        onClick={() => this.handleDelete(contact.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default Contacts;
