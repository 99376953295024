import React from 'react';
import CryptoJS from 'crypto-js';
import { useNavigation } from '../../utilities/NavigationContext';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { readFile, LLMTextToJSON } from '../../utilities/AI';

import History from '../PlanComponents/History';

import SmartPaste from '../../utilities/SmartPaste';
import DropFile from '../../utilities/DropFile';

const secretStorage = process.env.REACT_APP_SECRET_STORAGE;

// helper functions
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    if (!Array.isArray(problems)) {
        return []; // Return an empty array or handle the error as needed
    }

    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        priority : "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    }));
};

function generateRandomDate(start, end) {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('T')[0]; // Format as yyyy-mm-dd
}

function addFieldsToProblems(problems) {

    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    return problems.map(problem => ({
      ...problem,
      dateAdded: formattedDate,
      id: generateId(),
      priority: "Low",
      collapsed: true,
      review: [],
      journey: problem.journey.map(g => ({
        ...g,
        id: generateId(),
        dateAdded: formattedDate
      })),
      goal: problem.goal.map(g => ({
        ...g,
        id: generateId(),
        dateAdded: formattedDate
      })),
      plan: problem.plan.map(p => ({
        ...p,
        id: generateId(),
        dateAdded: formattedDate,
        status: "plan"
      }))
    }));
}

function updateIds(obj) {
    // Check if the object is an array
    if (Array.isArray(obj)) {
        obj.forEach(updateIds);
    } 
    // Check if the object is indeed an object (not null)
    else if (typeof obj === 'object' && obj !== null) {
        for (let key in obj) {
            // If the key is 'id' and its value is null, update it
            if (key === 'id' && obj[key] === null) {
                obj[key] = generateId();
            } else {
                // Recursively call updateIds for nested objects or arrays
                updateIds(obj[key]);
            }
        }
    }
}

// sample data
const history = {
    allergies: [{ id: generateId(), substance: "Bee sting", severity: "Moderate" }],
    medications: [
        { id: generateId(), name: "Atorvastatin", dose: 40, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Darolutamide", dose: 300, unit: "mg", form: "Tablet", frequency: "1 Tablet Twice a day" },
        { id: generateId(), name: "Metformin", dose: 1, unit: "g", form: "Tablet", frequency: "1 Tablet Twice a day" },
        { id: generateId(), name: "Olanzapine", dose: 10, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Olanzapine", dose: 5, unit: "mg", form: "Tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Sitagliptin", dose: 100, unit: "mg", form: "Tablet", frequency: "1 Tablet In the morning" },
        { id: generateId(), name: "Valproate Sodium", dose: 500, unit: "mg", form: "Enteric coated tablet", frequency: "1 Tablet In the evening" },
        { id: generateId(), name: "Xarelto", dose: 20, unit: "mg", form: "Tablet", frequency: "1 Tablet In the morning" }
    ],
    activeHistory: [
        { id: generateId(), date: "2014-12-26", condition: "Anxiety", details: "" },
        { id: generateId(), date: "2014-12-26", condition: "Bipolar affective disorder", details: "" },
        { id: generateId(), date: "2017-08-21", condition: "Type 2 Diabetes Mellitus", details: "" },
        { id: generateId(), date: "2018-02-01", condition: "Hepatic steatosis", details: "" },
        { id: generateId(), date: "2020-03-01", condition: "Prostate carcinoma", details: "Adenocarcinoma of the prostate, T2c Gleason 3+4, post radical prostatectomy/PLND April 2020, post salvage prostate bed RT, completed Jul 2021, another redo salvage pelvic RT completed Jul 2022" },
        { id: generateId(), date: "2020-07-31", condition: "Bilateral Pulmonary embolism", details: "" },
        { id: generateId(), date: "2020-10-02", condition: "Dyslipidaemia", details: "" }
    ],
    pastHistory: [
        { id: generateId(), date: "", condition: "Tonsillitis", details: "" },
        { id: generateId(), date: "1975-01-01", condition: "MBA", details: "" },
        { id: generateId(), date: "1975-01-01", condition: "Fractured tibia & fibula", details: "Post MBA" },
        { id: generateId(), date: "2016-02-24", condition: "Depression", details: "" },
        { id: generateId(), date: "2017-08-21", condition: "Tympanic perforation", details: "" },
        { id: generateId(), date: "2020-04-29", condition: "Prostatectomy, Open", details: "" },
        { id: generateId(), date: "2020-07-31", condition: "Deep venous thrombosis (Right)", details: "Extensive above & below DVT" }
    ],
    immunisations: [
        { id: generateId(), date: "1966-01-01", vaccine: "Hepatitis B (Hepatitis B)" },
        { id: generateId(), date: "2019-05-01", vaccine: "FluQuadri (Influenza)" },
        { id: generateId(), date: "2021-06-28", vaccine: "AstraZeneca VAXZEVRIA (COVID-19)" },
        { id: generateId(), date: "2021-09-20", vaccine: "AstraZeneca VAXZEVRIA (COVID-19)" },
        { id: generateId(), date: "2022-03-03", vaccine: "Pfizer Comirnaty (COVID-19)" },
        { id: generateId(), date: "2022-04-19", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2022-07-13", vaccine: "Pfizer Comirnaty (COVID-19)" },
        { id: generateId(), date: "2023-10-11", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2024-04-26", vaccine: "Fluad Quad (Influenza)" },
        { id: generateId(), date: "2024-08-07", vaccine: "Shingrix (Herpes Zoster)" }
    ],
    smoking: { id: generateId(), status: "Former", notes: "Quit in 2010 after 20 years of smoking." },
    alcohol: { id: generateId(), status: "Occasional", notes: "Drinks socially on weekends." },
    substance: { id: generateId(), status: "None", notes: "No history of substance abuse." },
    familyHistory: [
        { id: generateId(), relation: "Father", condition: "Hypertension", notes: "Diagnosed at age 50." },
        { id: generateId(), relation: "Mother", condition: "Type 2 Diabetes Mellitus", notes: "Diagnosed at age 60." }
    ],
    emergency: [
        { id: generateId(), name: "John Doe", relationship: "Spouse", phone: "+123456789" },
        { id: generateId(), name: "Jane Smith", relationship: "Daughter", phone: "+987654321" }
    ],
    carer: [
        { id: generateId(), name: "John Doe", relationship: "Spouse", phone: "+123456789" },
        { id: generateId(), name: "Jane Smith", relationship: "Daughter", phone: "+987654321" }
    ],
    cultural : { id: generateId(), language: "English", religion: "Christian", interpreter: "No", birthCountry: "Australia", otherCommunication: "None", otherCultural: "" },
    eduEmploy : { id: generateId(), educationLevel: "Primary degree", employment : "Not working"}
};
  

// AI instructions
const instructionTextToForm = `
    Your task is to organise the provided information from userInput into an ordered format.
    Use British spelling throughout.
    Only provide the information requested; do not add or summarise.
    Format your response as a flat JSON object.

    The information to retrieve is as follows:
    - First name, last name, date of birth, address, suburb, postcode, mobile phone
    - List of allergies
    - List of medications
    - List of active past history
    - List of inactive past history
    - List of immunisations
    - List of family history
    - List of emergency contacts
    - Smoking history
    - Alcohol history
    - Substance use History

    Return the information as a JSON object with the following structure:
    - patientData: {lastName: 'last name', firstName: 'first name', dob: 'date of birth, format as yyyy-mm-dd', address1: 'address line 1, address2: 'address line 2', suburb: 'suburb', postcode: 'postcode', contact: 'mobile phone'}
    - allergies: [{id: null, substance: 'substance causing the allergy', severity: 'severity of reaction to the allergen, if any'}]
    - medications: [{id : null, name: 'name of medication', dose: 'dose of the medication, e.g.40 or 100', unit: 'in mg or g or mcg', form: 'form of the medication, e.g., cap, tab, EC tab, syr', frequency: 'frequency of administration, e.g., once daily, twice a day, etc.'}]
    - activeHistory: [{id : null, date: 'date of the medical condition, format as yyyy-mm-dd; if the exact date is unknown, use "01" for both day and month', condition: 'description of the medical condition, e.g., anxiety, type-2 diabetes', details: [{date: 'date of the related event', journey: 'description of the event, e.g., diabetic retinopathy, diabetic nephropathy'}]}]
    - pastHistory: [{id: null, date: 'date of the medical condition, format as yyyy-mm-dd; if the exact date is unknown, use "01" for both day and month', condition: 'description of the medical condition, e.g., anxiety, type-2 diabetes', details: [{date: 'date of the related event', journey: 'description of the event, e.g., diabetic retinopathy, diabetic nephropathy'}]}]
    - immunisations: [{id: null, vaccine: 'name of the vaccine', date: 'date of immunisation, format as yyyy-mm-dd'}]
    - familhyHistory : [{id: null, relation: 'type of relationship eg. father, mother, grandfather, sister, etc.', condition: 'type of condition/disease, eg. diabetes, heart disease, coeliac disease, etc.', notes : 'any notes with regards to this condition'}]
    - emergency : [{id: null, name: 'name of emergency contact', relationship: 'relationship of the emergency contact, eg. friend, brother, sister, father, aunty, social worker, etc.}, phone: 'phone number of the emergency contact'}]
    - smoking: {id: null, status: 'status of current smoking, eg. stopped, ex-smoker, never smoke', notes: 'any notes with regards to the smoking'}
    - alcohol : {id: null, status : 'status of current alcohol intake, eg. heavy drinker, hazardous drinker, social drinker, not drinking', notes: 'any notes with regards to the alcohol drinking'}
    - substance: {id: null, status: 'list of substance taken, like caffeine, heroin, marijuana, coccaine, diazepam, or none', notes: 'any notes about the susbstance taken'}
    For allergies, medications, immunisations, familyHistory, emergency, activeHistory, pastHistory, and journey entries within activeHistory and pastHistory, there may be multiple entries.
    For any date field, if there's only a year, return the date as the year yyyy with mm = 01, dd = 01 (outcome format as yyyy-mm-dd).
    If no date is provided, or an invalid date is given, leave the field empty as "".
    If any field is not provided, return it as an empty ""
`;

const instructionTextToCDMPlan = `
 Your task is to process a list of medical problems and check if each problem has a corresponding reference in a predefined list. The list is in the referrenceData.
    Use British spelling throughout.
    Provide only the information requested; do not add or summarize.
    Format your response as a flat JSON object.

    Each reference includes a problem, goal, and plan. For each problem in the medical problems list:

    1. If the problem exists in the references, or if a similar disease type is found (e.g., "emphysema" can be considered similar to "COPD", subacromial bursitis can be considered as shoulder pain), return a JSON object containing the exact problem name as it appears in the references, the list of goals (with their descriptions and status as "plan"), and the list of plans (with their entries and status).
    2. If the problem does not exist in the references or no similar disease type is found, return a JSON object with the input problem and empty lists for goals and plans.
    3. Ensure that every problem in the medical problems list is represented in the output, even if it doesn't have a corresponding entry in the references.
    4. If there's journey data with the problem, add it as journey with this format : [{id: 'null', date:'return as yyyy-mm-dd', journey:'the journey data', type: 'it's either pathology, imaging, diagnosis, report or procedure'}]. if there's no data, just return as journey : []
    5. Format the response as: [{problem: "problem entry from references, indicate if there's location/side, example left, right, lower, upper, inferior, superior", goal: [list of goals], plan: [list of plans]}, journey: [associated data with the problem, eg. subacromial bursitis as part of shoulder pain, CT-scan lumbosacral as part of low back pain]].
    Here is an example:
    Given "emphysema" in the user input, search for "COPD" in the references. If found, return "COPD" as the problem name along with its goals and plans.
`

class General extends React.Component {
    constructor(props) {
        super(props);
        const savedFormData = localStorage.getItem('currentPatient');
        this.state = {
            referrences: [],
            dashboard: [],
            formData: savedFormData ? JSON.parse(savedFormData): {},
            isEditing: false,
            // AI processing
            isLoading: false,
            // Smart paste processin
            isLoadingPaste : false,
            smartPaste: "",
            isDrawerOpen : false,
        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });

        // Ref for the menu container
        this.menuRef = React.createRef();
    }
    
    componentDidMount() {
        const encryptedAdmin = localStorage.getItem('admin');
        if (encryptedAdmin) {
            const bytes = CryptoJS.AES.decrypt(encryptedAdmin, secretStorage);
            const adminData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            const referrences = adminData.contacts;
            const dashboard = adminData.dashboard;
            this.setState({ referrences: referrences, dashboard: dashboard });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if formData has changed and if it's not empty, then save to localStorage
        if (prevState.formData !== this.state.formData && Object.keys(this.state.formData).length !== 0) {
            this.updatePatientData();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Check if formData has changed and if it's not empty, then save to localStorage
        if (prevState.formData !== this.state.formData && Object.keys(this.state.formData).length !== 0) {
            this.updatePatientData();
        }
    }

    // // Local Storage method
    // saveToLocalStorage = () => {
    //     localStorage.setItem('currentPatient', JSON.stringify(this.state.formData));
    // };

    // clearLocalStorage = () => {
    //     localStorage.removeItem('currentPatient');
    //     this.render()
    // }
    
    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };


    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => {
            const isEditing = !prevState.isEditing;
            
            // Save to local storage when toggling from edit mode to view mode
            if (!isEditing) {
                this.updatePatientData();
            }
            
            return { isEditing };
        });
    };
    
    // Processing paste data
    handlePaste = async (pasteData, toastId) => {

            toast.update(toastId, {
                render: 'Processing data ...',
                autoClose: false,
                hideProgressBar: false
            });
            
            // Creating system command
            var processedInput = `${instructionTextToForm}\nUser: ${pasteData}`;
            const respond = await LLMTextToJSON(processedInput);
      
            if (respond) {
                this.setState({AIrespond : respond})
                //update the form     
                 this.AItoForm(respond, toastId);
                //  this.AItoPlan(respond, toastId);       
                 toast.update(toastId, {
                    render: 'All task done!',
                    type: toast.TYPE.SUCCESS,
                    autoClose: 1000,  
                    hideProgressBar: false
                });
    
            } else {
                console.error('Error fetching the response:', error);
                toast.update(toastId, {
                    render: 'Error pasting data!',
                    type: toast.TYPE.ERROR,
                    autoClose: 1000,  
                    hideProgressBar: false
                });
       
            }
    }

    // process File drop
    handleFile = async (file) => {
        const fileText = await readFile(file);
        console.log('File processed:', fileText);
        this.setState({loading : 'Processing file...'})
    }

    // Data to fill up History Form
    AItoForm = async(data, toastId) => {
        toast.update(toastId, {
            render: 'Filling up forms ...',
            autoClose: false,
            hideProgressBar: false
        });
        
     
        const profile = data.patientData
        const history = {
            activeHistory: data.activeHistory,
            pastHistory : data.pastHistory,
            medications: data.medications,
            immunisations: data.immmunisations,
            allergies: data.allergies,
            familyHistory: data.familyHistory,
            emergency: data.emergency,
            smoking: data.smoking,
            alcohol: data.alcohol,
            substance: data.substance,
        };
        updateIds(history);

        // Update the state
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                profile: profile,
                history: history
            }
        }), this.updatePatientData);
 

    }

    AItoPlan = async (pasteData, toastId) => {
        toast.update(toastId, {
            render: 'updating Chronic Disease Planning ...',
            autoClose: false,
            hideProgressBar: false
        });
        
        const activeData = JSON.stringify(pasteData, (key, value) => {
             return value;
        }, 2)

        const referrences = localStorage.getItem('referrences')
        const referrenceData = JSON.stringify(referrences);
        // Creating system command
        var processedInput = `${instructionTextToCDMPlan}\nUser: ${activeData} ${referrenceData}`;

        const respond = await LLMTextToJSON(processedInput);

        if (respond) {

            const cdm = addFieldsToProblems(respond);

            // Update the state
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    cdm
                }
            }), this.updatePatientData); 
    
            //update the form     
            //  this.AItoPlan(respond);

            // // create plan 
            // var plan = `${instructionTextToPlan}\nUser: ${newTranscription}`;
            // const planRespond = await LLMTextToJSON(plan);
            // if (planRespond) {
            //     this.AItoPlan(planRespond);
            // }

            // create review
            // var review = `${instructionTextToReview}\nUser: ${newTranscription}`;
            // const reviewRespond = await LLMTextToText(review);
            // if (reviewRespond) {
            //     this.AItoReview(reviewRespond);
            // }
        } else {
            console.error('Error fetching the response:', error);
   
        }
        this.setState({loading: ""})
    };

    
  
    // Function to update problems from child component
    // handleProblemsUpdate = (updatedProblems) => {
    //     this.setState((prevState) => ({
    //         formData: {
    //             ...prevState.formData,
    //             haplanning: updatedProblems,
    //         },
    //     }));
    // };

    updateFormData = (key, updatedData) => {
      
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [key]: updatedData,
          },
        }), this.saveToLocalStorage);
    };
    

    render() {
    
        const { formData } = this.state;

        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(320px, auto)' }}>
              
                <div className='col-span-6 flex justify-center gap-4'>
                <SmartPaste onPasteData={this.handlePaste} />
                {/* <DropFile onDropFile={this.handleFile} /> */}
                </div>
                <History
                    history={formData.history}
                    updateHistory={(updatedHistory) => this.updateFormData('history', updatedHistory)}
                />
                <ToastContainer />
            </div>
        );
    }

}

const GeneralhWithNavigation = (props) => {
        const navigate = useNavigation();
        return <General {...props} navigate={navigate} />;
};
    
export default GeneralhWithNavigation;