import { AITextToObject, AITextToText,  AITextToObjectwithDataAndRef, AITextToObjectwithData  } from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};



const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};


// Prompts
// const instructionTextToForm = `
//     You are a medical practitioner. Write a report as medical notes using only the provided input. Ensure British spelling is used throughout.

//     Specific guidelines:
//     - Only include treatment if it is explicitly mentioned.
//     - Skip the follow-up section if it is not provided.
//     - Do not add any additional comments or summaries.

//     Return the response as a JSON object with the following fields:
//     - presentingIssue
//     - history
//     - pastHistory
//     - childhoodHistory
//     - familyHistory
//     - parentChildNeed
//     - occupation
//     - social
//     - substance
//     - currentMedication
//     - allergies
//     - treatmentHistory
//     - medicationHistory
//     - relevantExamination
//     - relevantInvestigation
//     - devTesting
//     - mentalStateExamination
//     - risk
//     - tools
//     - diagnosis
//     - predisposing
//     - precipitating
//     - perpetuating
//     - protective
//     - reason
//     - cando
//     - help
//     - call
//     - talk
//     - professional
//     - triggers
//     - warningSigns
//     - copingStrategy
//     - support
//     - emergency

//     Detailed instructions for each field:
//     - presentingIssue: Briefly summarise the patient's current issues.
//     - history: Document the history of the current patient presentation.
//     - pastHistory: Include the patient's past medical and psychiatric history.
//     - childhoodHistory: Include any history of childhood trauma, abuse, learning difficulties, school bullying, ADHD, Autism, ODD, or any childhood consultations or medications.
//     - familyHistory: Record any family history of mental health issues, such as depression, anxiety, bipolar disorder, OCD, autism, ADHD, or learning difficulties.
//     - parentChildNeed: Note any specific needs related to parenting or child care.
//     - occupation: Document the patient’s employment history, including current and past employment, duration, and whether they are currently working.
//     - social: Detail the patient's living situation, including who they live with, housing status (renting or own home), and relationships with family and friends.
//     - substance: List any substance use, including smoking, alcohol, and illicit drugs like ice, cannabis, ecstasy, heroin, cocaine, or methamphetamine.
//     - currentMedication: List all medications the patient is currently taking.
//     - allergies: List any known allergies.
//     - treatmentHistory: Include any previous consultations, emergency attendances, or hospitalisations.
//     - medicationHistory: Document previous medications prescribed for mental health issues.
//     - relevantExamination: Record any relevant physical examination findings.
//     - relevantInvestigation: Include relevant investigations such as blood tests, imaging, or other diagnostic studies.
//     - devTesting: Note any previous psychological or developmental testing, such as IQ or cognitive testing.
//     - mentalStateExamination: Complete the mental state exam, using default values if not provided:
//         {
//             Appearance and Behaviour: Good posture, eye contact, appropriate attire,
//             Speech: Normal rate, normal volume,
//             Mood: Normal,
//             Affect: Congruent,
//             Thought Content: No delusions or suicidal ideation,
//             Thought Form: Normal,
//             Perception: No hallucinations,
//             Cognition: Not assessed, no memory issues,
//             Insight: Good
//         }
//     - risk: Assess for signs of suicidality, perceptual disturbances, delusions, hallucinations, substance use, and alcohol use.
//     - tools: List any assessment tools used, such as K10, DASS21, AQ score, ASRS, PCL-5, ACE.
//     - diagnosis: Provide a diagnosis based on the given information; do not fabricate any details.
//     - predisposing: List factors that may increase the risk for the presenting problem.
//     - precipitating: Document any events or stressors that may trigger or worsen symptoms.
//     - perpetuating: Identify conditions that maintain or worsen the problem.
//     - protective: Note any of the patient’s competencies, skills, or supportive elements that could be protective.
//     - reason: Record the patient’s reasons to live.
//     - cando: Specify actions the patient can take to stay safe.
//     - help: Indicate where the patient can seek help.
//     - call: List who the patient can call for support.
//     - talk: Note who the patient can talk to for emotional support.
//     - professional: Specify what professional help the patient can access.
//     - triggers: List any potential triggers for a relapse of mental health issues.
//     - warningSigns: Identify any warning signs of a relapse.
//     - copingStrategy: Document any techniques the patient is using to cope, including cognitive and behavioural strategies.
//     - support: Note any social and professional support available to the patient.
//     - emergency: Outline immediate actions to be taken in case of a relapse.

//     Handling data:
//     - If a field has no entry, return the field with "".
//     - Ensure that the 'problems' field is populated by reviewing the other fields for any issues or concerns that should be documented as problems.
//     - Do not include any summaries or additional comments at the end.`


const instructionTextToForm = `
        You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

        Data Types Provided:
        1. 'input': Text input containing new medical notes.
        2. 'initData': A JSON array where each object represents an existing medical problem with the following fields:
           mhcp : 
           - presentingIssue: Overview of the patient's current issues.
           - history: Details of the current presentation's history.
           - pastHistory: Past medical and psychiatric history.
           - childhoodHistory: Childhood trauma, abuse, learning difficulties, school bullying, ADHD, Autism, ODD, or childhood consultations/medications.
           - familyHistory: Family mental health history, including depression, anxiety, bipolar disorder, OCD, autism, ADHD, or learning difficulties.
           - parentChildNeed: Special needs related to parenting or childcare.
           - occupation: Employment history, covering current/past jobs, durations, and current employment status.
           - social: Living situation, including household members, housing status, and relationships with family/friends.
           - substance: Substance use history, including smoking, alcohol, and drugs (ice, cannabis, ecstasy, heroin, cocaine, methamphetamine).
           - currentMedication: Current medications.
           - allergies: Known allergies.
           - treatmentHistory: Past consultations, emergency visits, or hospitalisations.
           - medicationHistory: Previous medications for mental health issues.
           - relevantExamination: Key findings from physical examinations.
           - relevantInvestigation: Investigations (e.g., blood tests, imaging, diagnostics).
           - devTesting: Past psychological or developmental tests (e.g., IQ or cognitive testing).
           - mentalStateExamination: Complete the mental state exam, using the following defaults if not provided:
               * Appearance and Behaviour: Good posture, eye contact, appropriate attire
               * Speech: Normal rate, normal volume
               * Mood: Normal
               * Affect: Congruent
               * Thought Content: No delusions or suicidal ideation
               * Thought Form: Normal
               * Perception: No hallucinations
               * Cognition: Not assessed, no memory issues
               * Insight: Good
           - risk: Assessment of suicidality, perceptual disturbances, delusions, hallucinations, substance and alcohol use.
           - tools: Any assessment tools used (e.g., K10, DASS21, AQ score, ASRS, PCL-5, ACE).
           - diagnosis: Diagnosis based on available information, without assumptions.
           mhformulation :
           - predisposing: Document any risk factors that increase the likelihood of the current issue. These may include family history of mental health conditions, past trauma, genetic vulnerabilities, personality traits, or chronic physical health conditions.
           - precipitating: Note recent events or stressors that have triggered or worsened the symptoms. Examples include major life changes, financial hardships, job loss, relationship issues, or recent illnesses or injuries.
           - perpetuating: Identify factors that sustain or worsen the current issue. These may include ongoing stressors, maladaptive coping strategies, lack of social support, chronic medical issues, or environmental factors that prevent recovery.
           - protective: Describe the patient's strengths, skills, or resources that could serve as protective factors. These may include social support networks, problem-solving skills, access to therapy, hobbies, coping mechanisms, or a stable living environment.
           mhsafety : 
           - reason: The patient's personal motivations or meaningful connections that give them reasons to continue living.
           - cando: Practical actions the patient can take to ensure their own safety and well-being.
           - help: Resources and support systems available to the patient that can provide assistance when needed.
           - call: Trusted individuals the patient can contact for immediate support during difficult times.
           - talk: People the patient can turn to for emotional support and open communication about their feelings.
           - professional: Access to professional help.
           relapsePrevention : 
           - triggers: Specific situations, environments, or stressors that might lead to a recurrence of the patient's mental health symptoms. Identifying these helps the patient and their support network manage and avoid potential relapse situations.
           - warningSigns: Early indicators that a relapse may be occurring or that the patient's mental health is beginning to deteriorate. Recognising these signs allows for timely intervention before symptoms become severe.
           - copingStrategy: Techniques or practices the patient uses to manage their mental health symptoms and stressors. Effective coping strategies enable patients to maintain stability and manage symptoms independently.
           - support: Any sources of assistance available to the patient, both social and professional, that contribute to their overall wellbeing. Support networks provide help during difficult times and enhance the patient's resilience.
           - emergency: Actions to be taken immediately in case of a relapse, ensuring the patient's safety and facilitating access to necessary support quickly.

        Instructions:
        - Use data from the ‘input’ to update each corresponding field in ‘initData’. Skip fields if no relevant information is provided.
        - Return a JSON object structured as follows, using "" for any empty fields:
           - presentingIssue
           - history
           - pastHistory
           - childhoodHistory
           - familyHistory
           - parentChildNeed
           - occupation
           - social
           - substance
           - currentMedication
           - allergies
           - treatmentHistory
           - medicationHistory
           - relevantExamination
           - relevantInvestigation
           - devTesting
           - mentalStateExamination
           - risk
           - tools
           - diagnosis
           - predisposing
           - precipitating
           - perpetuating
           - protective
           - reason
           - cando
           - help
           - call
           - talk
           - professional
           - triggers
           - warningSigns
           - copingStrategy
           - support
           - emergency

        Data Handling:
        - For any field without data, return it as "".
        - Populate the ‘problems’ field by identifying any issues from other fields.
        - Exclude any summaries or additional comments.
    `


// const instructionTextToPlan = `
//  You are a medical practitioner. Your job is to identify list of problems from the input.
//  Ensure British spelling is used throughout.
//  Return respond as JSON Object.
// Identify any potential issues/problems based on the input. 
// Create a separate entry for each problem and avoid combining multiple problems into a single entry. 
// Each problem should be structured as follows:
//         {
//             id: null,
//             problem: "Problem description",
//             notes: "Details about the problem if applicable",
//             goal: [
//                 {
//                     id: null,
//                     description: "Goal description if applicable"
//                 }
//             ],
//             plan: [
//                 {
//                     id: null,
//                     entry: "Plan description if applicable",
//                     status: "plan"
//                 }
//             ]
//         }
// `


const instructionTextToPlan = `You are a medical practitioner.

Use British spelling.

You will receive three types of data:
1. 'input': A text containing medical notes.
2. 'initData': A JSON array of objects, each representing an existing medical problem. Each object has fields for:
   - "problem": The title of the problem.
   - "goal": A list of goals for the problem.
   - "plan": A list of tasks for the problem.
   - "review": A list of review notes for the problem.
   - "journey": A list tracking the patient's journey for this problem.
   - "category": Category of this problem.
3. 'references' (optional): A JSON array containing reference data for medical problems, with each entry containing fields for "problem", "goal", and "todo" (tasks).

### Task:

1. Read through the notes provided in 'input'.
2. Identify and create a list of medical problems based on the content in 'input'.
3. For each identified problem in 'input', check if it exists in 'initData'.

4. If the identified problem does not exist in 'initData':
   - **If 'references' is available**: Check if the problem exists in 'references'. If found, use the title, goals, and todo list from 'references'. 
   - **If 'references' is unavailable or the problem is not found in 'references'**: Create a new title based on the problem description from 'input'.
   - Add a new problem entry in 'initData' with the following fields:
     - "problem": Use the title from 'references' if available; otherwise, use a title based on the problem description from 'input'.
     - "goal": Use goals from 'references' if available. Add any additional goals from 'input' in this format: '[{id: null, description: "goal description"}]'.
     - "plan": Use the todo list from 'references' if available. Add additional tasks from 'input' in this format: '[{id: null, entry: "task description", status: "plan"}]'.
     - "review": Add a single review entry in the format: '[{id: null, date: "yyyy-mm-dd" (today's date), notes: "note about the problem"}]'.
     - "journey": Add a single journey entry in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date or as mentioned), journey: "note about the journey", type: "either diagnosis, pathology, imaging, medication, procedure, expertise"}]'.
       - Use 'diagnosis' if it’s a new diagnosis.
       - Use 'pathology' for blood test results.
       - Use 'imaging' for imaging results (X-ray, MRI, CT-scan, etc.).
       - Use 'medication' for new or changed medications.
       - Use 'procedure' for surgical or endoscopic procedures.
       - Use 'expertise' for reports from allied health or specialists.
     - "priority": Set to "Low".
     - "collapsed": Set to 'false'.
     - "category": Set to "Mental Health" or "Lifestyle", or "Social" depends on the problem. 

5. If the identified problem already exists in 'initData', update the existing entry as follows:
   - **Goals**: Add any new goals from 'input' in the format: '[{id: null, description: "goal description"}]'.
   - **Plan**: Add any new tasks from 'input' in the format: '[{id: null, entry: "task description", status: "plan"}]'.
     - If a task matches an existing entry in "plan", update its status based on the input:
       - Set 'status' to "doing" if the task is ongoing.
       - Set 'status' to "done" if the task is completed.
   - **Review**: Append any additional review notes from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date), notes: "review note"}]'.
   - **Journey**: Append any new journey entries from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (date mentioned or today’s date), journey: "journey note", type: "diagnosis, pathology, imaging, medication, procedure, expertise"}]'.

Return the updated 'initData' as a JSON array of objects.

Do not add any comments or explanations; only output the JSON array.`;

const instructionTextToReview = `
You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:

Use short sentences and simple points.
Organize the notes in this specific order:
History: Summarize key points of the patient's history concisely.
Examination: Include details of any physical or mental state examination conducted, if applicable.
Assessment: List any identified problems, issues, or diagnoses.Use ICD 10 diagnosis if possible. 
Management: Outline any plans or actions to be taken, if applicable. Do not provide any information if not provided.
Write the content directly underneath each title. If no content is provided for a section, skip that title. Do not include anything outside the input.
`
// AI Methods

// text to Form
export const textToForm = async (text, formData) => {


    const fieldsToKeep = ["mhcp", "mhformulation", "mhsafety", "relapsePrevention" ];
    const MHOnly = Object.keys(formData)
    .filter(key => fieldsToKeep.includes(key))
    .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
    }, {});

    console.log(MHOnly)

    // Creating system command
    const data = await AITextToObjectwithData(instructionTextToForm, text, MHOnly);
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const mhcp = {
        presentingIssue: data.presentingIssue,
        history: data.history,
        pastHistory: data.pastHistory,
        childhoodHistory: data.childhoodHistory,
        familyHistory: data.familyHistory,
        parentChildneed: data.parentChildNeed,
        occupation :data.occupation,
        social: data.social,
        substance : data.substance,
        currentMedication: data.currentMedication,
        allergies : data.allergies,

        treatmentHistory: data.treatmentHistory,
        medicationHistory: data.medicationHistory,

        relevantExamination : data.relevantExamination,
        relevantInvestigation: data.relevantInvestigation,
        devTesting : data.devTesting,


        mentalStateExamination: data.mentalStateExamination,
        risk: data.risk,
        tools: data.tools,
        diagnosis: data.diagnosis,

        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhformulation = {
        predisposing: data.predisposing,
        precipitating: data.precipitating,
        perpetuating: data.perpetuating,
        protective: data.protective,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };
    
    const mhsafety = {
        reason: data.reason,
        cando: data.cando,
        help: data.help,
        call: data.call,
        talk: data.talk,
        professional: data.professional,
        date: data.timeStamp ? data.timeStamp : formattedDate
    };

    const relapsePrevention = {
        triggers: data.triggers,
        warningSigns: data.warningSigns,
        copingStrategy: data.copingStrategy,
        support: data.support,
        emergency: data.emergency
    }

    if (mhcp && mhformulation && mhsafety && relapsePrevention) {
        return ({mhcp, mhformulation, mhsafety, relapsePrevention})
    } else {
        console.log("Error")
        return null;
    }
}

// // text to plan
// export const textToPlan = async (text, formData) => {
//     const data = await AITextToObject(instructionTextToPlan, text, formData);
//     const plan = regenerateIds(data);
//     return plan;
// }

// text to plan
export const textToPlan = async (input, prevData, referrences) => {

    const data = await AITextToObjectwithDataAndRef(instructionTextToPlan, input, prevData, referrences)
    const plan = regenerateIds(data);
    return plan;
}


// text to review
export const textToReview = async (text, reviews) => {
    const data = await AITextToText(instructionTextToReview, text, reviews);
    const reviewData = [...(reviews || [])];
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const newReview = { date: formattedDate, review: data };
    
    reviewData.push(newReview);
    return reviewData;

}

