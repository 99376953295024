import { AITextToObject} from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
        education: Array.isArray(problem.education) ? problem.education.map(e => ({
            ...e,
            id: generateId(),
        })) : [] // Fallback to an empty array if education is not present or not an array
    });

    // Ensure `problems` is an array; wrap in an array if it's a single object
    const problemsArray = Array.isArray(problems) ? problems : [problems];

    // Map over array of problems and regenerate ids
    return problemsArray.map(createProblemWithIds);
};


//prompts

const instructionTextToPlan = `
You are a medical practitioner. 
Your task is to identify and return a list of problems from the input. 
Each problem should be a separate entry in the JSON array. 
Ensure that British spelling is used throughout. 
Return the response strictly as a JSON array without any comments or additional text outside of the JSON array. 
Each problem entry should follow this structure:
[
    {
        "id": null,
        "problem": "Problem description",
        "category": "Either Chronic Disease, Mental Health, Lifestyle, Preventive, or Social",
        "goal": [
            {
                "id": null,
                "entry": "Goal description if applicable"
            }
        ],
        "plan": [
            {
                "id": null,
                "entry": "Plan description if applicable",
                "status": "plan"
            }
        ],
        "education": [
            {
                "id": null,
                "entry": "Education title",
                "link": "url link of the education"
            }
        ]
    }
]

Make sure each problem is distinct and avoid combining multiple problems into a single entry.
`;


// text to plan
export const textToPlan = async (text) => {
    const data = await AITextToObject(instructionTextToPlan, text);
    const plan = regenerateIds(data);
    console.log(plan)
    return plan;
}