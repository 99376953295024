import { Outlet, Navigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const [auth, setAuth] = useAuth();
    const hasCheckedSession = useRef(false); // Ref to track if the session has been checked

    // Function to check the session and update the state
    const checkSession = async () => {
        if (hasCheckedSession.current) return;

        try {
            const response = await axios.get(`${EndPoint}/session`, { withCredentials: true });
            setAuth({ ...auth, isLoggedIn: response.data.isLoggedIn });
        } catch (error) {
            console.error('Error checking session:', error);
            setAuth({ ...auth, isLoggedIn: false });
            navigate('/login');
        }

        hasCheckedSession.current = true;
    };

    // Effect to reset the session check flag when auth.isLoggedIn changes
    useEffect(() => {
        hasCheckedSession.current = false;
    }, [auth.isLoggedIn]);

    return(
        auth.isLoggedIn ? <Outlet/> : <Navigate to="/login"/>
    )
}

export default PrivateRoutes