import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EndPoint = process.env.REACT_APP_API_ENDPOINT;
const ClientPoint = process.env.REACT_APP_CLIENT_ENDPOINT;

const InterviewProject = () => {
  const [formData, setFormData] = useState({
    userId: '6682a1d1654e6cde295d4fad',
    title: '',
    description: '',
    interviewInstruction: '',
    formInstruction: ''
  });
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${EndPoint}/project/all`);
        setAllProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
    fetchProjects();
  }, []);

  useEffect(() => {
    if (selectedProjectId) {
      const currentProject = allProjects.find(project => project._id === selectedProjectId);
      if (currentProject) {
        setFormData({
          userId: currentProject.userId,
          title: currentProject.title,
          description: currentProject.description,
          interviewInstruction: currentProject.interviewInstruction,
          formInstruction: currentProject.formInstruction
        });
      }
    }
  }, [selectedProjectId, allProjects]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (selectedProjectId) {
        const response = await axios.put(`${EndPoint}/project/${selectedProjectId}`, formData);
        setAllProjects(allProjects.map(project =>
          project._id === selectedProjectId ? response.data : project
        ));
        setSelectedProjectId(null);
      } else {
        const response = await axios.post(`${EndPoint}/project`, formData);
        setAllProjects([...allProjects, response.data]);
      }
      setFormData({
        userId: '6682a1d1654e6cde295d4fad',
        title: '',
        description: '',
        interviewInstruction: '',
        formInstruction: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleDeleteProject = async () => {
    if (selectedProjectId) {
      try {
        await axios.delete(`${EndPoint}/project/${selectedProjectId}`);
        setAllProjects(allProjects.filter(project => project._id !== selectedProjectId));
        setSelectedProjectId(null);
        setFormData({
          userId: '6682a1d1654e6cde295d4fad',
          title: '',
          description: '',
          interviewInstruction: '',
          formInstruction: ''
        });
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
  };

  const showProject = (id) => {
    setSelectedProjectId(id);
  };

  const handleNewProject = () => {
    setSelectedProjectId(null);
    setFormData({
      userId: '6682a1d1654e6cde295d4fad',
      title: '',
      description: '',
      interviewInstruction: '',
      formInstruction: ''
    });
  };

  const handleCopyLink = () => {
    const url = `${ClientPoint}/interview?id=${selectedProjectId}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy URL: ", err);
      });
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="mt-20 mx-10">
        <button
          onClick={handleNewProject}
          className="text-lg font-bold text-white p-2 mb-4 bg-green-600 hover:bg-green-700 rounded shadow w-full"
        >
          New
        </button>
        {allProjects.map((project) => (
          <div
            onClick={() => showProject(project._id)}
            className="cursor-pointer bg-slate-200 hover:bg-sky-400 p-4 m-2 text-md rounded shadow"
            key={project._id}
          >
            {project.title}
          </div>
        ))}
      </div>
      <div className="mt-10 w-full">
        <h2 className="font-bold text-center text-xl m-4">Project Form</h2>
        <form onSubmit={handleSubmit} className="m-4">
          {/* URL and Project ID (conditionally rendered if selectedProjectId is present) */}
          {selectedProjectId && (
            <>
              <div className="grid grid-cols-4 m-4 items-center">
                <label>URL</label>
                <input
                  type="text"
                  value={`${ClientPoint}/interview?id=${selectedProjectId}`}
                  readOnly
                  className="border-slate-200 rounded col-span-2 bg-gray-100 cursor-not-allowed"
                />
                <button
                  type="button"
                  onClick={handleCopyLink}
                  className="text-sm font-bold text-white p-2  mx-2 bg-sky-600 hover:bg-sky-700 rounded shadow"
                >
                  Copy Link
                </button>
              </div>
              <div className="grid grid-cols-4 m-4">
                <label>Project ID</label>
                <input
                  type="text"
                  value={selectedProjectId}
                  readOnly
                  className="border-slate-200 rounded col-span-3 bg-gray-100 cursor-not-allowed"
                />
              </div>
            </>
          )}
          <div className="grid grid-cols-4 m-4">
            <label>User ID</label>
            <input
              type="text"
              name="userId"
              value={formData.userId}
              onChange={handleChange}
              required
              className="border-slate-200 rounded col-span-3 bg-gray-100 cursor-not-allowed"
            />
          </div>
          <div className="grid grid-cols-4 m-4">
            <label>Title</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              className="border-slate-200 rounded col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 m-4">
            <label>Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              className="h-[200px] resize-none border-slate-200 rounded col-span-3"
            ></textarea>
          </div>
          <div className="grid grid-cols-4 m-4">
            <label>Interview Instruction</label>
            <textarea
              name="interviewInstruction"
              value={formData.interviewInstruction}
              onChange={handleChange}
              required
              className="h-[500px] resize-none border-slate-200 rounded col-span-3"
            ></textarea>
          </div>
          <div className="grid grid-cols-4 m-4">
            <label>Form Instruction</label>
            <textarea
              name="formInstruction"
              value={formData.formInstruction}
              onChange={handleChange}
              required
              className="h-[500px] resize-none border-slate-200 rounded col-span-3"
            ></textarea>
          </div>
          <button type="submit" className="text-lg font-bold text-white p-4 bg-sky-700 hover:bg-sky-800 rounded shadow w-full">
            {selectedProjectId ? 'Update' : 'Submit'}
          </button>
          <button
            type="button"
            onClick={handleDeleteProject}
            className="text-lg font-bold text-white p-4 bg-red-600 hover:bg-red-700 rounded shadow w-full mt-4"
          >
            Delete
          </button>
        </form>
      </div>
    </div>
  );
};

export default InterviewProject;
