import { AITextToObject, AITextToText, AITextToObjectwithData, AITextToObjectwithDataAndRef } from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    });

    // Check if `problems` is not an array, and convert to array of one object
    if (!Array.isArray(problems)) {
        return [createProblemWithIds(problems)];
    }

    // Map over array of problems and regenerate ids
    return problems.map(createProblemWithIds);
};



const notEmpty = (obj) => {
    return Object.keys(obj).some(key => {
        if (key === 'date') return false; // Skip 'date'
        const value = obj[key];

        if (typeof value === 'string') {
            return value.trim() !== ""; // Check for non-empty string
        } else if (typeof value === 'object' && value !== null) {
            // Check for non-empty object (including non-empty nested objects)
            return Object.keys(value).length > 0;
        } else {
            return !!value; // For booleans, numbers, etc.
        }
    });
};


// Prompts
const instructionTextToForm = `
    You are a medical practitioner tasked with creating a structured report based on provided data. Use British spelling throughout.

        Data Types Provided:
        1. 'input': Text input containing new medical notes.
        2. 'initData': A JSON array where each object represents an existing medical problem with the following fields:
        - medicalhistory: Describe the patient's current medical issues. Default is "No current medical issues reported."
        - pastmedical: List the patient's past medical conditions. Default is "None."
        - allergies: Include all known allergies and reactions, with each entry on a separate line. Default is "No known allergies."
        - familyhistory: Detail the patient's family medical history. Default is "None."
        - alcohol: Document the patient's alcohol consumption habits. Default is "No alcohol consumption reported."
        - smoking: Provide the patient's smoking history. Default is "No smoking history reported."
        - substance: Include details of any substance use (e.g., cannabis, heroin, cocaine, etc.). Default is "No substance use reported."
        - medications: List current medications and dosages, each on a separate line. Default is "No current medications."
        - medicationarrangement: Information about the prescription provider, pharmacy, use of Webster packs, and the last community pharmacy review. Default is "No specific medication arrangement details provided."
        - vision: Include any issues related to vision, such as glasses, surgeries, or visual aids. Default is "No vision issues reported."
        - hearing: Document the patient's hearing status, including the use of hearing aids. Default is "No hearing issues reported."
        - fallandhomesafety: Record any falls in the past 12 months, use of safety aids like grab rails, and personal safety alarms. Default is "No falls or safety concerns reported."
        - skin: Describe any skin-related conditions. Default is "No skin conditions reported."
        - feet: Document any foot-related issues, including toenail problems, gait, footwear, and podiatrist visits. Default is "No foot issues reported."
        - nutrition: Detail the patient's nutritional habits, including meal preparation, portion sizes, and use of dentures or PEG. Default is "No nutritional issues reported."
        - oralhealth: Provide information on dental health, including the use of dentures, implants, and any oral conditions. Default is "No oral health issues reported."
        - cognition: Address any cognitive issues, such as memory, comprehension, or processing speed. Default is "No cognitive issues reported."
        - mentalhealth: Record any mental health concerns, including mood disturbances, delusions, or suicidal thoughts. Default is "No mental health issues reported."
        - urineContinence: Document urinary continence status, use of pads, and any impact on daily life. Default is "No urinary continence issues reported."
        - faecalContinence: Record fecal continence status, use of pads, and any impact on daily life. Default is "No fecal continence issues reported."
        - mobility: Describe the patient's mobility status, including the use of aids and access to community travel. Default is "No mobility issues reported."
        - activity: List the patient's hobbies, social activities, and club memberships. Default is "No activities or hobbies reported."
        - social: Provide details on the patient's social support network, including friends, family, and social groups. Default is "No social support network reported."
        - housing: Describe the patient's living arrangements (e.g., own home, residential facility). Default is "No specific housing arrangements reported."
        - legal: Document any legal matters, including wills and power of attorney. Default is "No legal issues reported."
        - finance: Provide information on how the patient's finances are managed, including decision-makers. Default is "No financial management issues reported."
        - driving: Detail the patient's driving status, including any assessments and use of alternative transportation. Default is "No driving issues reported."
        - advancedcaredirective: Include information on the existence and details of an advanced care directive. Default is "No advanced care directive reported."
        - teamcare: List the healthcare team involved in the patient's care, including specialists and allied health professionals. Default is "No healthcare team reported."
        - services: Document any external services involved in the patient's care (e.g., aged care, community services). Default is "No external services involved."
        - examination: Provide details of any recent medical examinations. Default is "No recent examinations reported."
        - surveillance: Record any future surveillance plans (e.g., cancer screenings). Default is "No surveillance plans reported."
        - vaccination: List any vaccinations that have been administered or are pending. Default is "No vaccinations reported."
   

        Instructions:
        - Use data from the ‘input’ to update each corresponding field in ‘initData’. Skip fields if no relevant information is provided.
        - Return a JSON object structured as follows, using "" for any empty fields:
            - medicalhistory
            - pastmedical
            - allergies
            - familyhistory
            - alcohol
            - smoking
            - substance
            - medications
            - medicationarrangement
            - vision
            - hearing
            - fallandhomesafety
            - skin
            - feet
            - nutrition
            - oralhealth
            - cognition
            - mentalhealth
            - urineContinence
            - faecalContinence
            - mobility
            - activity
            - social
            - housing
            - legal
            - finance
            - driving
            - advancedcaredirective
            - teamcare
            - services
            - examination
            - surveillance
            - vaccination
        Data Handling :
        - For any field without data, return it as "".
        - Exclude any summaries or additional comments.

    `;

const instructionTextToPlan = `You are a medical practitioner.

    Use British spelling.
    
    You will receive three types of data:
    1. 'input': A text containing medical notes.
    2. 'initData': A JSON array of objects, each representing an existing medical problem. Each object has fields for:
       - "problem": The title of the problem.
       - "goal": A list of goals for the problem.
       - "plan": A list of tasks for the problem.
       - "review": A list of review notes for the problem.
       - "journey": A list tracking the patient's journey for this problem.
       - "category": Category of this problem.
    3. 'references' (optional): A JSON array containing reference data for medical problems, with each entry containing fields for "problem", "goal", and "todo" (tasks).
    
    ### Task:
    
    1. Read through the notes provided in 'input'.
    2. Identify and create a list of medical problems based on the content in 'input'.
    3. For each identified problem in 'input', check if it exists in 'initData'.
    
    4. If the identified problem does not exist in 'initData':
       - **If 'references' is available**: Check if the problem exists in 'references'. If found, use the title, goals, and todo list from 'references'. 
       - **If 'references' is unavailable or the problem is not found in 'references'**: Create a new title based on the problem description from 'input'.
       - Do not add, change or rewrite any content not explicitly present in the input.
       - Add a new problem entry in 'initData' with the following fields:
         - "problem": Use the title from 'references' if available; otherwise, use a title based on the problem description from 'input'.
         - "goal": Use goals from 'references' if available. Add any additional goals from 'input' in this format: '[{id: null, description: "goal description"}]'.
         - "plan": Use the todo list from 'references' if available. Add additional tasks from 'input' in this format: '[{id: null, entry: "task description", status: "plan"}]'.
         - "review": Add a single review entry in the format: '[{id: null, date: "yyyy-mm-dd" (today's date), notes: "note about the problem"}]'.
         - "journey": Add a single journey entry in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date or as mentioned), journey: "note about the journey", type: "either diagnosis, pathology, imaging, medication, procedure, expertise"}]'.
           - Use 'diagnosis' if it’s a new diagnosis.
           - Use 'pathology' for blood test results.
           - Use 'imaging' for imaging results (X-ray, MRI, CT-scan, etc.).
           - Use 'medication' for new or changed medications.
           - Use 'procedure' for surgical or endoscopic procedures.
           - Use 'expertise' for reports from allied health or specialists.
         - "priority": Set to "Low".
         - "collapsed": Set to 'false'.
         - "category": Set to "chronic Disease", "Mental Health", "Lifestyle", or "Social" depends on the problem. 
    
    5. If the identified problem already exists in 'initData', update the existing entry as follows:
       - **Goals**: Add any new goals from 'input' in the format: '[{id: null, description: "goal description"}]'. Keep the original.
       - **Plan**: Add any new tasks from 'input' in the format: '[{id: null, entry: "task description", status: "plan"}]'. Keep the original.
         - If a task matches an existing entry in "plan", update its status based on the input:
           - Set 'status' to "doing" if the task is ongoing.
           - Set 'status' to "done" if the task is completed.
           - Set 'status' to 'pending' if the task is pending or on hold.
       - **Review**: Append any additional review notes from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (today’s date), notes: "review note"}]'.
       - **Journey**: Append any new journey entries from 'input' in the format: '[{id: null, date: "yyyy-mm-dd" (date mentioned or today’s date), journey: "journey note", type: "diagnosis, pathology, imaging, medication, procedure, expertise"}]'.
       - **Category** : don't make any changes
       - **Priority** : don't make any changes
    Make sure no problems from initial 'initData' is removed. 
    Return the updated 'initData' as a JSON array of objects.
    
    Do not add any comments or explanations; only output the JSON array.`;
   
const instructionTextToReview = `
   You are a medical practitioner tasked with writing simplified medical notes from the provided input. Follow these guidelines:
   
   Use short sentences and simple points.
   Organize the notes in this specific order:
   History: Summarize key points of the patient's history concisely.
   Examination: Include details of any physical or mental state examination conducted, if applicable.
   Assessment: List any identified problems, issues, or diagnoses. Use ICD 10 diagnosis if possible. 
   Management: Outline any plans or actions to be taken, if applicable.Do not provide any information if not provided.
   Write the content directly underneath each title. If no content is provided for a section, skip that title. Do not include anything outside the input.
   `

   
// text to Form
export const textToForm = async (text, formData) => {

    // Creating system command
    const data = await AITextToObjectwithData(instructionTextToForm, text, formData.healthassessment);
    console.log(data)

    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    const filledForm = {
        medicalHistory: data.medicalhistory,
        pastMedical: data.pastmedical,
        allergies: data.allergies,
        familyHistory: data.familyhistory,
        alcohol: data.alcohol,
        smoking: data.smoking,
        substance: data.substance,
        medications: data.medications,
        medicationArrangement: data.medicationarrangement,
        vision: data.vision,
        hearing: data.hearing,
        fallAndHomeSafety: data.fallandhomesafety,
        skin: data.skin,
        feet: data.feet,
        nutrition: data.nutrition,
        oralHealth: data.oralhealth,
        cognition: data.cognition,
        mentalHealth: data.mentalhealth,
        urineContinence: data.urineContinence,
        faecalContinence: data.faecalContinence,
        mobility: data.mobility,
        activity: data.activity,
        social: data.social,
        housing: data.housing,
        legal: data.legal,
        finance: data.finance,
        driving: data.driving,
        advancedCareDirective: data.advancedcaredirective,
        services: data.services,
        examination: data.examination,
        surveillance: data.surveillance,
        vaccination: data.vaccination, 
        date: formattedDate
    };

    if (filledForm) {
        return filledForm
    } else {
        console.log("Error")
        return null;
    }
}

// text to plan
export const textToPlan = async (input, prevData, referrences) => {
    const data = await AITextToObjectwithDataAndRef(instructionTextToPlan, input, prevData, referrences)
    const plan = regenerateIds(data);
    return plan;
}

// text to review
export const textToReview = async (text, reviews) => {
    const data = await AITextToText(instructionTextToReview, text, reviews);
    const reviewData = [...(reviews || [])];
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];
    const newReview = { date: formattedDate, review: data };
    
    reviewData.push(newReview);
    return reviewData;

}