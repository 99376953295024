import { AITextToObject} from './AI';


// Helpers

function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    const createProblemWithIds = (problem) => ({
        ...problem,
        id: generateId(),
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
        education: Array.isArray(problem.education) ? problem.education.map(e => ({
            ...e,
            id: generateId(),
        })) : [] // Fallback to an empty array if education is not present or not an array
    });

    // Ensure `problems` is an array; wrap in an array if it's a single object
    const problemsArray = Array.isArray(problems) ? problems : [problems];

    // Map over array of problems and regenerate ids
    return problemsArray.map(createProblemWithIds);
};


//prompts

const instructionTextToContact = `
You are a medical receptionist. 
Your task is to read the provided text, which contains contact details. 
Each contact detail should be a separate entry in the JSON array.

Ensure that British spelling is used throughout. 
Return the response strictly as a JSON array without any comments or additional text outside of the JSON array. 

Each contact entry should follow this structure:

[
    {
        "id": "",
        "title": "",
        "firstName": "",
        "lastName": "",
        "profession": "",
        "providerNumber": "",
        "address1": "",
        "address2": "",
        "suburb": "",
        "postcode": "",
        "state": "",
        "phone": "",
        "fax": "",
        "email": ""
    }
]

Accepted titles: ['Mr', 'Mrs', 'Ms', 'Dr']
Australian states: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'TAS', 'ACT', 'NT']
Professions include:
- General Practitioner (GP)
- Pediatrician
- Cardiologist
- Dermatologist
- Neurologist
- Oncologist
- Ophthalmologist
- Orthopedic Surgeon
- Psychiatrist
- Radiologist
- Anesthesiologist
- Endocrinologist
- Gastroenterologist
- Hematologist
- Infectious Disease Specialist
- Nephrologist
- Obstetrician/Gynecologist (OB/GYN)
- Pulmonologist
- Rheumatologist
- Urologist
- Surgeon
- Plastic Surgeon
- Emergency Medicine Physician
- Pathologist
- Otolaryngologist (ENT)
- Allergy and Immunology Specialist
- Critical Care Medicine Specialist
- Geriatrician
- Geneticist
- Sports Medicine Specialist
- Podiatrist
- Occupational Medicine Specialist
- Physiatrist (Physical Medicine and Rehabilitation)
- Family Medicine Physician
- Hospitalist
- Medical Geneticist
- Pain Management Specialist
- Sleep Medicine Specialist
- Nuclear Medicine Specialist
- Preventive Medicine Specialist
- Physiotherapist
- Occupational Therapist
- Speech Pathologist
- Dietitian
- Diabetes Educator
- Pharmacist
- Audiologist
- Social Worker
- Chiropractor
- Osteopath
- Exercise Physiologist
- Prosthetist/Orthotist
- Medical Laboratory Scientist
- Medical Imaging Technologist
- Psychologist
- Clinical Psychologist
- Counsellor
- Mental Health Nurse
- Case Manager
- Medical Power of Attorney (MPOA)
- Care Worker
- Aged Care Worker
- Disability Support Worker
- Home Care Assistant
- Personal Care Assistant (PCA)
`;



// text to plan
export const textToContact = async (text) => {
    const data = await AITextToObject(instructionTextToContact, text);
    const plan = regenerateIds(data);
    return plan;
}