import React from 'react';
import SmartPaste from '../../utilities/SmartPaste';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToPlan } from '../../utilities/ReferrencesAI';

// Helper function to generate unique IDs
function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const samplePaste = `
Ashtma	
Goal : 
Maintain best lung function 
identify and avoid trigger factors, 
optimise medication regimen, 
non-smoking, 
Regular aerobic exercise.
Annual flu vaccination
Seven yearly Pneumovax.
Plan : 	
Inhalers review annually
Asthma Plan review annually
Respiratory Physician if indicated


Atrial Fibrillation	
Goal : 
Minimise symptoms of AF
Decrease the risks of AF including stroke	
Control rate – aiming <110 beats per minute
Plan : 
monitor and titrate medication therapy
Determine stroke risk (CHA2DS2VA) 
Determine bleeding risk (HASBLED)
Consider Cardiologist referral
Echocardiography

Coeliac Disease	
Goal : 
Manage and monitor symptoms
Optimise nutritional levels
Monitor for associated autoimmune conditions	
Maintain a gluten free diet
Plan : 
Annual nutritional bloods
2 yearly BMD	
Dietician referral
`
const defaultReferrences = [
    {
      problem: "Diabetes Type 2",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Achieve target blood glucose levels" },
        { id: generateId(), entry: "Reduce A1C to below 7%" },
        { id: generateId(), entry: "Improve dietary habits" }
      ],
      plan: [
        { id: generateId(), entry: "Prescribe oral hypoglycemic agents" },
        { id: generateId(), entry: "Introduce regular blood sugar monitoring" },
        { id: generateId(), entry: "Provide dietary counseling" }
      ],
      education: [
        { id: generateId(), title: "Managing Type 2 Diabetes", link: "https://www.youtube.com/watch?v=example_link1" },
        { id: generateId(), title: "Diabetes Diet Tips", link: "https://www.youtube.com/watch?v=example_link2" }
      ]
    },
    {
      problem: "Hypertension",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Reduce blood pressure to target levels" },
        { id: generateId(), entry: "Encourage a low-sodium diet" },
        { id: generateId(), entry: "Increase physical activity" }
      ],
      plan: [
        { id: generateId(), entry: "Prescribe antihypertensive medication" },
        { id: generateId(), entry: "Schedule regular BP monitoring" },
        { id: generateId(), entry: "Provide lifestyle modification guidance" }
      ],
      education: [
        { id: generateId(), title: "Understanding and Managing High Blood Pressure", link: "https://www.youtube.com/watch?v=example_link3" },
        { id: generateId(), title: "Lifestyle Changes for Hypertension", link: "https://www.youtube.com/watch?v=example_link4" }
      ]
    },
    {
      problem: "Depression",
      category: "Mental Health",
      goal: [
        { id: generateId(), entry: "Reduce depressive symptoms" },
        { id: generateId(), entry: "Improve daily functioning" },
        { id: generateId(), entry: "Enhance coping strategies" }
      ],
      plan: [
        { id: generateId(), entry: "Provide cognitive behavioral therapy" },
        { id: generateId(), entry: "Consider SSRI medication" },
        { id: generateId(), entry: "Encourage regular physical activity" }
      ],
      education: [
        { id: generateId(), title: "Understanding Depression", link: "https://www.youtube.com/watch?v=example_link5" },
        { id: generateId(), title: "Coping Strategies for Depression", link: "https://www.youtube.com/watch?v=example_link6" }
      ]
    },
    {
      problem: "Obesity",
      category: "Chronic Disease",
      goal: [
        { id: generateId(), entry: "Achieve a healthy weight" },
        { id: generateId(), entry: "Improve dietary habits" },
        { id: generateId(), entry: "Increase physical activity" }
      ],
      plan: [
        { id: generateId(), entry: "Offer weight loss counseling" },
        { id: generateId(), entry: "Introduce an exercise routine" },
        { id: generateId(), entry: "Monitor weight and progress" }
      ],
      education: [
        { id: generateId(), title: "The Truth About Weight Loss", link: "https://www.youtube.com/watch?v=example_link7" },
        { id: generateId(), title: "Nutrition and Weight Management", link: "https://www.youtube.com/watch?v=example_link8" }
      ]
    },
    {
      problem: "Social Isolation",
      category: "Social",
      goal: [
        { id: generateId(), entry: "Increase social interactions" },
        { id: generateId(), entry: "Encourage participation in community events" },
        { id: generateId(), entry: "Reduce feelings of loneliness" }
      ],
      plan: [
        { id: generateId(), entry: "Connect with local support groups" },
        { id: generateId(), entry: "Encourage family visits" },
        { id: generateId(), entry: "Suggest volunteer opportunities" }
      ],
      education: [
        { id: generateId(), title: "Coping with Loneliness", link: "https://www.youtube.com/watch?v=example_link9" },
        { id: generateId(), title: "Building Social Connections", link: "https://www.youtube.com/watch?v=example_link10" }
      ]
    },
    {
      problem: "Smoking",
      category: "Lifestyle",
      goal: [
        { id: generateId(), entry: "Reduce cigarette consumption" },
        { id: generateId(), entry: "Work towards complete cessation" },
        { id: generateId(), entry: "Improve lung health" }
      ],
      plan: [
        { id: generateId(), entry: "Provide smoking cessation counseling" },
        { id: generateId(), entry: "Introduce nicotine replacement therapy" },
        { id: generateId(), entry: "Monitor progress and setbacks" }
      ],
      education: [
        { id: generateId(), title: "How to Quit Smoking", link: "https://www.youtube.com/watch?v=example_link11" },
        { id: generateId(), title: "The Effects of Smoking on Health", link: "https://www.youtube.com/watch?v=example_link12" }
      ]
    },

  {
    problem: "Asthma",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Control asthma symptoms" },
      { id: generateId(), entry: "Reduce asthma exacerbations" },
      { id: generateId(), entry: "Improve lung function" }
    ],
    plan: [
      { id: generateId(), entry: "Prescribe inhaled corticosteroids" },
      { id: generateId(), entry: "Provide asthma action plan" },
      { id: generateId(), entry: "Schedule regular follow-ups" }
    ],
    education: [
      { id: generateId(), title: "Managing Asthma", link: "https://www.youtube.com/watch?v=example_link13" },
      { id: generateId(), title: "Asthma Action Plan", link: "https://www.youtube.com/watch?v=example_link14" }
    ]
  },
  {
    problem: "Atrial Fibrillation",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Control heart rate" },
      { id: generateId(), entry: "Prevent stroke" },
      { id: generateId(), entry: "Manage symptoms" }
    ],
    plan: [
      { id: generateId(), entry: "Prescribe anticoagulants" },
      { id: generateId(), entry: "Consider beta-blockers or calcium channel blockers" },
      { id: generateId(), entry: "Monitor ECG regularly" }
    ],
    education: [
      { id: generateId(), title: "Understanding Atrial Fibrillation", link: "https://www.youtube.com/watch?v=example_link15" },
      { id: generateId(), title: "Atrial Fibrillation and Stroke Prevention", link: "https://www.youtube.com/watch?v=example_link16" }
    ]
  },
  {
    problem: "Coeliac Disease",
    category: "Chronic Disease",
    goal: [
      { id: generateId(), entry: "Achieve gluten-free diet adherence" },
      { id: generateId(), entry: "Prevent nutritional deficiencies" },
      { id: generateId(), entry: "Manage gastrointestinal symptoms" }
    ],
    plan: [
      { id: generateId(), entry: "Provide gluten-free diet counseling" },
      { id: generateId(), entry: "Schedule regular nutritional monitoring" },
      { id: generateId(), entry: "Refer to a dietitian" }
    ],
    education: [
      { id: generateId(), title: "Living with Coeliac Disease", link: "https://www.youtube.com/watch?v=example_link17" },
      { id: generateId(), title: "Gluten-Free Diet Tips", link: "https://www.youtube.com/watch?v=example_link18" }
    ]
  },
  {
    problem: "PTSD",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce PTSD symptoms" },
      { id: generateId(), entry: "Improve emotional regulation" },
      { id: generateId(), entry: "Enhance coping mechanisms" }
    ],
    plan: [
      { id: generateId(), entry: "Provide trauma-focused therapy" },
      { id: generateId(), entry: "Consider SSRIs" },
      { id: generateId(), entry: "Monitor symptoms regularly" }
    ],
    education: [
      { id: generateId(), title: "Understanding PTSD", link: "https://www.youtube.com/watch?v=example_link19" },
      { id: generateId(), title: "PTSD Coping Strategies", link: "https://www.youtube.com/watch?v=example_link20" }
    ]
  },
  {
    problem: "Alcohol Misuse",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Reduce alcohol intake" },
      { id: generateId(), entry: "Avoid binge drinking" },
      { id: generateId(), entry: "Achieve complete abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Provide counseling for alcohol misuse" },
      { id: generateId(), entry: "Consider support group referral" },
      { id: generateId(), entry: "Monitor progress regularly" }
    ],
    education: [
      { id: generateId(), title: "Effects of Alcohol on Health", link: "https://www.youtube.com/watch?v=example_link21" },
      { id: generateId(), title: "Strategies for Reducing Alcohol Intake", link: "https://www.youtube.com/watch?v=example_link22" }
    ]
  },
  {
    problem: "Benzodiazepine Dependency",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce benzodiazepine use" },
      { id: generateId(), entry: "Prevent withdrawal symptoms" },
      { id: generateId(), entry: "Achieve long-term abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Implement tapering schedule" },
      { id: generateId(), entry: "Provide behavioral therapy" },
      { id: generateId(), entry: "Refer to addiction specialist if necessary" }
    ],
    education: [
      { id: generateId(), title: "Understanding Benzodiazepine Dependence", link: "https://www.youtube.com/watch?v=example_link23" },
      { id: generateId(), title: "Coping with Withdrawal", link: "https://www.youtube.com/watch?v=example_link24" }
    ]
  },
  {
    problem: "Cannabis Use Disorder",
    category: "Mental Health",
    goal: [
      { id: generateId(), entry: "Reduce cannabis consumption" },
      { id: generateId(), entry: "Improve mental clarity" },
      { id: generateId(), entry: "Achieve abstinence" }
    ],
    plan: [
      { id: generateId(), entry: "Provide counseling for substance use" },
      { id: generateId(), entry: "Monitor for withdrawal symptoms" },
      { id: generateId(), entry: "Consider support group referral" }
    ],
    education: [
      { id: generateId(), title: "Understanding Cannabis Use Disorder", link: "https://www.youtube.com/watch?v=example_link25" },
      { id: generateId(), title: "Cannabis and Mental Health", link: "https://www.youtube.com/watch?v=example_link26" }
    ]
  },
  {
    problem: "Caffeine Addiction",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Reduce caffeine intake" },
      { id: generateId(), entry: "Avoid dependency on caffeine" },
      { id: generateId(), entry: "Improve sleep quality" }
    ],
    plan: [
      { id: generateId(), entry: "Provide strategies for reducing caffeine intake" },
      { id: generateId(), entry: "Monitor for withdrawal symptoms" },
      { id: generateId(), entry: "Encourage regular sleep patterns" }
    ],
    education: [
      { id: generateId(), title: "Effects of Caffeine on the Body", link: "https://www.youtube.com/watch?v=example_link27" },
      { id: generateId(), title: "How to Reduce Caffeine Intake", link: "https://www.youtube.com/watch?v=example_link28" }
    ]
  },
  {
    problem: "Financial Constraint",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Improve financial management" },
      { id: generateId(), entry: "Reduce debt" },
      { id: generateId(), entry: "Achieve financial stability" }
    ],
    plan: [
      { id: generateId(), entry: "Provide financial counseling" },
      { id: generateId(), entry: "Offer budgeting resources" },
      { id: generateId(), entry: "Connect to financial aid programs" }
    ],
    education: [
      { id: generateId(), title: "Managing Finances", link: "https://www.youtube.com/watch?v=example_link29" },
      { id: generateId(), title: "Budgeting Basics", link: "https://www.youtube.com/watch?v=example_link30" }
    ]
  },
  {
    problem: "Unemployment",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Secure employment" },
      { id: generateId(), entry: "Develop job skills" },
      { id: generateId(), entry: "Improve resume and interview skills" }
    ],
    plan: [
      { id: generateId(), entry: "Provide job search assistance" },
      { id: generateId(), entry: "Connect with vocational training programs" },
      { id: generateId(), entry: "Offer resume and interview coaching" }
    ],
    education: [
      { id: generateId(), title: "Job Hunting Tips", link: "https://www.youtube.com/watch?v=example_link31" },
      { id: generateId(), title: "Building a Strong Resume", link: "https://www.youtube.com/watch?v=example_link32" }
    ]
  },
  {
    problem: "Homelessness",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Secure stable housing" },
      { id: generateId(), entry: "Improve access to resources" },
      { id: generateId(), entry: "Connect with support services" }
    ],
    plan: [
      { id: generateId(), entry: "Provide housing assistance" },
      { id: generateId(), entry: "Connect to local shelters" },
      { id: generateId(), entry: "Offer mental health support" }
    ],
    education: [
      { id: generateId(), title: "Understanding Homelessness", link: "https://www.youtube.com/watch?v=example_link33" },
      { id: generateId(), title: "Resources for the Homeless", link: "https://www.youtube.com/watch?v=example_link34" }
    ]
  },
  {
    problem: "Intimate Partner Violence",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Enhance personal safety" },
      { id: generateId(), entry: "Access support services" },
      { id: generateId(), entry: "Build a support network" }
    ],
    plan: [
      { id: generateId(), entry: "Provide crisis counseling" },
      { id: generateId(), entry: "Connect with local shelters" },
      { id: generateId(), entry: "Offer legal assistance" }
    ],
    education: [
      { id: generateId(), title: "Understanding Intimate Partner Violence", link: "https://www.youtube.com/watch?v=example_link35" },
      { id: generateId(), title: "Resources for Victims", link: "https://www.youtube.com/watch?v=example_link36" }
    ]
  },
  {
    problem: "Not Driving",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Identify alternative transportation" },
      { id: generateId(), entry: "Improve access to essential services" },
      { id: generateId(), entry: "Maintain social connections" }
    ],
    plan: [
      { id: generateId(), entry: "Provide transportation resources" },
      { id: generateId(), entry: "Connect with local mobility services" },
      { id: generateId(), entry: "Encourage ridesharing options" }
    ],
    education: [
      { id: generateId(), title: "Transportation Alternatives", link: "https://www.youtube.com/watch?v=example_link37" },
      { id: generateId(), title: "Accessing Services without a Car", link: "https://www.youtube.com/watch?v=example_link38" }
    ]
  },
  {
    problem: "Legal Issues",
    category: "Social",
    goal: [
      { id: generateId(), entry: "Resolve ongoing legal matters" },
      { id: generateId(), entry: "Understand legal rights" },
      { id: generateId(), entry: "Access legal representation" }
    ],
    plan: [
      { id: generateId(), entry: "Provide legal counseling" },
      { id: generateId(), entry: "Refer to local legal aid services" },
      { id: generateId(), entry: "Monitor case progress" }
    ],
    education: [
      { id: generateId(), title: "Understanding Legal Rights", link: "https://www.youtube.com/watch?v=example_link39" },
      { id: generateId(), title: "Finding Legal Assistance", link: "https://www.youtube.com/watch?v=example_link40" }
    ]
  },
  {
    problem: "Lack of Activities",
    category: "Lifestyle",
    goal: [
      { id: generateId(), entry: "Increase engagement in activities" },
      { id: generateId(), entry: "Encourage physical activity" },
      { id: generateId(), entry: "Develop hobbies and interests" }
    ],
    plan: [
      { id: generateId(), entry: "Provide list of local activities" },
      { id: generateId(), entry: "Encourage participation in community events" },
      { id: generateId(), entry: "Suggest physical exercise routines" }
    ],
    education: [
      { id: generateId(), title: "Benefits of Staying Active", link: "https://www.youtube.com/watch?v=example_link41" },
      { id: generateId(), title: "Finding New Hobbies", link: "https://www.youtube.com/watch?v=example_link42" }
    ]
  }
  ];
  


class Referrences extends React.Component {
    constructor(props) {
        super(props);
        
        const referrencesData = props.data || defaultReferrences;

        this.state = {
            referrences: referrencesData,
            selectedProblem: null,
            editingProblemId: null,
            editingGoalId: null,
            editingPlanId: null,
            editingCategoryId: null,
            editingEducationId: null,
            editingFieldValues: {}, 
            newProblem: "",
            newCategory: "Chronic Disease",
            newGoals: [{ id: generateId(), entry: "" }],
            newPlans: [{ id: generateId(), entry: "" }],
            newEducation: [{ id: generateId(), entry: "", link: "" }],
            fileHandle: null
        };
    }

    componentDidMount() {
        // Load practices from localStorage on mount
        const adminData = this.props.loadFromLocalStorage();
        if (adminData) {
          this.setState({ referrences: adminData.referrences || [] });
        }
      }

    // Update component state when parent data changes
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data && this.props.data !== this.state.referrences) {
            this.setState({
                referrences: this.props.data || defaultReferrences,
                selectedProblem: null,
                editingProblemId: null,
                editingGoalId: null,
                editingPlanId: null,
                editingFieldValues: {}, 
                newProblem: "",
                newCategory: "Chronic Disease",
                newGoals: [{ id: generateId(), entry: "" }],
                newPlans: [{ id: generateId(), entry: "" }],
                newEducation: [{ id: generateId(), entry: "", link: "" }]
            })
        }
    }

    handleProblemClick = (problemId) => {
        this.setState({ selectedProblem: problemId });
    };

    handleDoubleClick = (id, type, field) => {
        if (type === 'editingEducationId') {
            // Separate entry and link for education item
            this.setState({
                [type]: id,
                editingFieldValues: { 
                    ...this.state.editingFieldValues, 
                    [`entry-${id}`]: field.entry, 
                    [`link-${id}`]: field.link 
                }
            });
        } else {
            // Handle other cases (e.g., problem, goal, plan, category)
            this.setState({
                [type]: id,
                editingFieldValues: { 
                    ...this.state.editingFieldValues, 
                    [id]: field 
                }
            });
        }
    };
    

    handleKeyPress = (e, id, type) => {
        if (e.key === 'Enter' || e.type === 'click') {
            this.handleSaveField(id, type);
        }
    };
    
    handleSaveField = (id, type) => {
        const { referrences, editingFieldValues } = this.state;
    
        const updatedReferrences = referrences.map(ref => {
            if (ref.id === this.state.selectedProblem) {
                if (type === 'editingGoalId') {
                    ref.goal = ref.goal.map(goal =>
                        goal.id === id
                            ? { ...goal, entry: editingFieldValues[id] || goal.entry }
                            : goal
                    );
                }
                if (type === 'editingPlanId') {
                    ref.plan = ref.plan.map(plan =>
                        plan.id === id
                            ? { ...plan, entry: editingFieldValues[id] || plan.entry }
                            : plan
                    );
                }
                if (type === 'editingProblemId') {
                    ref.problem = editingFieldValues[`problem-${id}`] || ref.problem;
                }
                if (type === 'editingCategoryId') {
                    ref.category = editingFieldValues[`category-${id}`] || ref.category;
                }
                if (type === 'editingEducationId') {
                    ref.education = ref.education.map(edu =>
                        edu.id === id
                            ? {
                                ...edu,
                                entry: editingFieldValues[`entry-${id}`] || edu.entry,
                                link: editingFieldValues[`link-${id}`] || edu.link
                            }
                            : edu
                    );
                }
            }
            return ref;
        });
    
        this.setState({
            referrences: updatedReferrences,
            editingProblemId: null,
            editingGoalId: null,
            editingPlanId: null,
            editingCategoryId: null,
            editingEducationId: null,
            editingFieldValues: {}
        });
    
        this.props.saveToFileSystem({ referrences: updatedReferrences });
        this.props.saveToLocalStorage({ referrences: updatedReferrences });
    };
    
    
    handleInputChange = (e, key) => {
        const { value } = e.target;
        this.setState(
            {
                editingFieldValues: { ...this.state.editingFieldValues, [key]: value }
            },
            () => console.log("editingFieldValues:", this.state.editingFieldValues) // Check if it logs the updated value.
        );
    };
    handleAddGoal = () => {
        const newGoal = { id: generateId(), entry: "" }; // Set a default entry
        
        this.setState(prevState => {
            const updatedReferrences = prevState.referrences.map(ref => {
                if (ref.id === prevState.selectedProblem) {
                    return { ...ref, goal: [...ref.goal, newGoal] };
                }
                return ref;
            });
    
            return {
                referrences: updatedReferrences,
                editingGoalId: newGoal.id,
                editingFieldValues: { ...prevState.editingFieldValues, [newGoal.id]: newGoal.entry }
            };
        }, () => {
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    
    handleAddPlan = () => {
        const newPlan = { id: generateId(), entry: "" }; // Set a default entry
        
        this.setState(prevState => {
            const updatedReferrences = prevState.referrences.map(ref => {
                if (ref.id === prevState.selectedProblem) {
                    return { ...ref, plan: [...ref.plan, newPlan] };
                }
                return ref;
            });
    
            return {
                referrences: updatedReferrences,
                editingPlanId: newPlan.id,
                editingFieldValues: { ...prevState.editingFieldValues, [newPlan.id]: newPlan.entry }
            };
        }, () => {
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    

    handleAddEducation = () => {
        const newEducation = { id: generateId(), entry: "", link: "" };
        this.setState(prevState => {
            const updatedReferrences = prevState.referrences.map(ref => {
                if (ref.id === prevState.selectedProblem) {
                    return { ...ref, education: [...(ref.education || []), newEducation] };
                }
                return ref;
            });

            return {
                referrences: updatedReferrences,
                editingEducationId: newEducation.id,
                editingFieldValues: { ...prevState.editingFieldValues, [newEducation.id]: "" }
            };
        }, () => {
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };

    handleDeleteProblem = (problemId) => {
        this.setState((prevState) => ({
            referrences: prevState.referrences.filter(ref => ref.id !== problemId),
            selectedProblem: null
        }));
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleDeleteGoal = (problemId, goalId) => {
        const updatedReferrences = this.state.referrences.map(ref => {
            if (ref.id === problemId) {
                ref.goal = ref.goal.filter(goal => goal.id !== goalId);
            }
            return ref;
        });
        this.setState({ referrences: updatedReferrences });
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleDeletePlan = (problemId, planId) => {
        const updatedReferrences = this.state.referrences.map(ref => {
            if (ref.id === problemId) {
                ref.plan = ref.plan.filter(plan => plan.id !== planId);
            }
            return ref;
        });
        this.setState({ referrences: updatedReferrences });
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleDeleteEducation = (problemId, educationId) => {
        const updatedReferrences = this.state.referrences.map(ref => {
            if (ref.id === problemId) {
                ref.edu = ref.plan.filter(edu => edu.id !== educationId);
            }
            return ref;
        });
        this.setState({ referrences: updatedReferrences });
        this.props.saveToFileSystem({ referrences: this.state.referrences });
        this.props.saveToLocalStorage({ referrences: this.state.referrences });
    };

    handleNewInputChange = (e, type, index = null, fieldType = "entry") => {
        const { value } = e.target;
    
        if (type === "problem") {
            this.setState({ newProblem: value });
        } else if (type === "category") {
            this.setState({ newCategory: value });
        } else if (type === "goal" && index !== null) {
            const newGoals = [...this.state.newGoals];
            newGoals[index].entry = value;
            this.setState({ newGoals });
        } else if (type === "plan" && index !== null) {
            const newPlans = [...this.state.newPlans];
            newPlans[index].entry = value;
            this.setState({ newPlans });
        } else if (type === "education" && index !== null) {
            const newEducation = [...this.state.newEducation];
            newEducation[index][fieldType] = value; // Update either entry or link based on fieldType
            this.setState({ newEducation });
        }
    };
    

    handleSaveNewProblem = () => {
        const { newProblem, newCategory, newGoals, newPlans, newEducation, referrences } = this.state;
        if (newProblem.trim() === "") return;
    
        const newReference = {
            id: generateId(),
            problem: newProblem,
            category: newCategory,
            goal: newGoals,
            plan: newPlans,
            education: newEducation
        };
    
        this.setState(prevState => ({
            referrences: [...prevState.referrences, newReference],
            selectedProblem: newReference.id,
            newProblem: "",
            newCategory: "Chronic Disease",
            newGoals: [{ id: generateId(), entry: "" }],
            newPlans: [{ id: generateId(), entry: "" }],
            newEducation: [{ id: generateId(), entry: "", link: "" }]
        }), () => {
            // Save to parent after state is updated
            this.props.saveToFileSystem({ referrences: this.state.referrences });
            this.props.saveToLocalStorage({ referrences: this.state.referrences });
        });
    };
    
    AIPaste = async (text, toastId) => {
         // filling up form
         toast.update(toastId, {
            render: 'Reading plan..',
            autoClose: false,
            hideProgressBar: false
        });

        var respond = await textToPlan(text);

        // updating localStorage

        if (respond) {
            const combinedReferrences = [...this.state.referrences, ...respond];

            this.setState({ referrences: combinedReferrences }, () => {
                // Now this.state.referrences is updated, and you can safely call your functions
                this.props.saveToFileSystem({ referrences: this.state.referrences });
                this.props.saveToLocalStorage({ referrences: this.state.referrences });
            });
        }

        // all done
        toast.update(toastId, {
            render: 'All task done!',
            type: toast.TYPE.SUCCESS,
            autoClose: 1000,  
            hideProgressBar: false
        });

    }

    render() {
        const { referrences, selectedProblem, editingFieldValues, newProblem, newCategory, newGoals, newPlans, newEducation } = this.state;
        const selectedReference = referrences.find(ref => ref.id === selectedProblem);

        return (
            <div className="m-20 p-4 bg-slate-100 rounded shadow">
                <p className="text-3xl font-bold p-4 mb-8 flex justify-center">Referrences</p>
                <div><SmartPaste onPasteData={this.AIPaste}/></div>

                <div className="grid grid-cols-2 gap-4">
                    <div className="bg-white p-4 shadow rounded">
                        <strong>Problems</strong>
                        <button onClick={() => this.setState({ selectedProblem: null })} className="bg-sky-800 text-white p-2 rounded shadow w-full my-6">
                            Add New Problem
                        </button>
                        {referrences.map(ref => (
                            <div key={ref.id} className="flex justify-between p-2 m-2 rounded shadow bg-gray-200 cursor-pointer" onClick={() => this.handleProblemClick(ref.id)}>
                                {ref.problem} 
                            </div>
                        ))}
                    </div>

                    <div className="bg-white p-4 shadow rounded">
                        {!selectedReference ? (
                            <>
                                <input
                                    type="text"
                                    value={newProblem}
                                    onChange={(e) => this.handleNewInputChange(e, "problem")}
                                    placeholder="Enter new problem"
                                    className="w-full p-2 border border-gray-300 rounded mb-4"
                                />
                                <div className="mb-4">
                                    <strong>Category</strong>
                                    <select
                                        value={newCategory}
                                        onChange={(e) => this.handleNewInputChange(e, "category")}
                                        className="w-full p-2 border border-gray-300 rounded mt-2"
                                    >
                                        <option value="Chronic Disease">Chronic Disease</option>
                                        <option value="Mental Health">Mental Health</option>
                                        <option value="Lifestyle">Lifestyle</option>
                                        <option value="Social">Social</option>
                                        <option value="Preventive">Preventive</option>
                                    </select>
                                </div>

                                <div className="goal-section mb-4">
                                    <strong>Goals</strong>
                                    {newGoals.map((goal, index) => (
                                        <div key={goal.id} className="goal-div bg-slate-200 hover:bg-slate-300 text-black m-2 p-2 rounded shadow">
                                            <input
                                                type="text"
                                                value={goal.entry}
                                                onChange={(e) => this.handleNewInputChange(e, "goal", index)}
                                                placeholder="Enter goal"
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddGoal} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Goal</button>
                                </div>

                                <div className="plan-section">
                                    <strong>Plans</strong>
                                    {newPlans.map((plan, index) => (
                                        <div key={plan.id} className="plan-div bg-sky-200 hover:bg-sky-300 m-2 p-2 rounded shadow">
                                            <input
                                                type="text"
                                                value={plan.entry}
                                                onChange={(e) => this.handleNewInputChange(e, "plan", index)}
                                                placeholder="Enter plan"
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddPlan} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Plan</button>
                                </div>
                                <div className="education-section mb-4">
                                    <strong>Education</strong>
                                    {newEducation.map((edu, index) => (
                                        <div key={edu.id} className="education-div bg-gray-200 hover:bg-gray-300 m-2 p-2 rounded shadow">
                                            <input
                                                type="text"
                                                placeholder="Education entry"
                                                value={edu.entry}
                                                onChange={(e) => this.handleNewInputChange(e, "education", index, "entry")}
                                                className="w-full p-2 mb-2 border border-gray-300 rounded"
                                            />
                                            <input
                                                type="text"
                                                placeholder="Link to pdf or video"
                                                value={edu.link}
                                                onChange={(e) => this.handleNewInputChange(e, "education", index, "link")}
                                                className="w-full p-2 border border-gray-300 rounded"
                                            />
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddEducation} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">
                                        Add Education
                                    </button>
                                </div>

                                <button onClick={this.handleSaveNewProblem} className="bg-sky-800 text-white p-2 rounded text-lg font-semibold shadow w-full mt-8">
                                    Save Problem
                                </button>
                            </>
                        ) : (
                            <>
                                {/* Selected problem and editing functionality */}
                                <div className="flex justify-between bg-sky-800 text-white p-2 rounded shadow">
                                    {this.state.editingProblemId === selectedReference.id ? (
                                        <input
                                            type="text"
                                            value={editingFieldValues[selectedReference.id] || ""}
                                            onChange={(e) => this.handleInputChange(e, selectedReference.id)}
                                            onKeyPress={(e) => this.handleKeyPress(e, selectedReference.id, 'editingProblemId')}
                                            className="w-full p-2 text-black"
                                            autoFocus
                                        />
                                    ) : (
                                        <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(selectedReference.id, 'editingProblemId', selectedReference.problem)}>
                                            {selectedReference.problem}
                                        </div>
                                    )}
                                    <div>
                                        <button className="text-white font-semibold" onClick={() => this.handleDeleteProblem(selectedReference.id)}>
                                            X
                                        </button>
                                    </div>
                                </div>

                                {/* Editing Category */}
                              <div className="category-section m-4">
                                    <strong>Category</strong>
                                    {this.state.editingCategoryId === selectedReference.id ? (
                                        <div>
                                            <select
                                                value={editingFieldValues[`category-${selectedReference.id}`] || selectedReference.category || "Chronic Disease"}
                                                onChange={(e) => this.handleInputChange(e, `category-${selectedReference.id}`)}
                                                className="w-full p-2 text-black rounded border border-gray-300"
                                                autoFocus
                                            >
                                                <option value="Chronic Disease">Chronic Disease</option>
                                                <option value="Mental Health">Mental Health</option>
                                                <option value="Lifestyle">Lifestyle</option>
                                                <option value="Social">Social</option>
                                                <option value="Preventive">Preventive</option>
                                            </select>
                                            <button
                                                onClick={() => this.handleSaveField(selectedReference.id, 'editingCategoryId')}
                                                className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    ) : (
                                        <div
                                            className="cursor-pointer m-2 bg-gray-300 p-2 rounded"
                                            onDoubleClick={() => this.handleDoubleClick(selectedReference.id, 'editingCategoryId', selectedReference.category || "Chronic Disease")}
                                        >
                                            {selectedReference.category || "Chronic Disease"}
                                        </div>
                                    )}
                                </div>




                                {/* Goals and Plans Section */}
                                <div className="goal-section m-4">
                                    <strong>Goals</strong>
                                    {selectedReference.goal.map(goal => (
                                        <div key={goal.id} className="flex justify-between items-center bg-slate-200 hover:bg-slate-300 text-black m-2 p-2 rounded shadow">
                                            {this.state.editingGoalId === goal.id ? (
                                                <input
                                                    type="text"
                                                    value={editingFieldValues[goal.id] || ""}
                                                    onChange={(e) => this.handleInputChange(e, goal.id)}
                                                    onKeyPress={(e) => this.handleKeyPress(e, goal.id, 'editingGoalId')}
                                                    className="w-full p-2 mr-2 rounded border border-gray-100"
                                                    autoFocus
                                                />
                                            ) : (
                                                <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(goal.id, 'editingGoalId', goal.entry)}>
                                                    {goal.entry}
                                                </div>
                                            )}
                                            <div>
                                                <button className="text-gray-800 font-semibold" onClick={() => this.handleDeleteGoal(selectedReference.id, goal.id)}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddGoal} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Goal</button>
                                </div>

                                <div className="plan-section m-4">
                                    <strong>Plans</strong>
                                    {selectedReference.plan
                                    .filter(plan => plan !== null)  
                                    .map(plan => (
                                        <div key={plan.id} className="flex justify-between items-center bg-sky-200 hover:bg-sky-300 m-2 p-2 rounded shadow">
                                            {this.state.editingPlanId === plan.id ? (
                                                <input
                                                    type="text"
                                                    value={editingFieldValues[plan.id] !== undefined ? editingFieldValues[plan.id] : plan.entry}
                                                    onChange={(e) => this.handleInputChange(e, plan.id)}
                                                    onKeyPress={(e) => this.handleKeyPress(e, plan.id, 'editingPlanId')}
                                                    className="w-full p-2 mr-2 rounded border border-gray-100"
                                                    autoFocus
                                                />
                                            ) : (
                                                <div className="cursor-pointer" onDoubleClick={() => this.handleDoubleClick(plan.id, 'editingPlanId', plan.entry)}>
                                                    {plan.entry}
                                                </div>
                                            )}
                                            <div>
                                                <button className="text-gray-800 font-semibold" onClick={() => this.handleDeletePlan(selectedReference.id, plan.id)}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                    <button onClick={this.handleAddPlan} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">Add Plan</button>
                                </div>


                                {/* Education Section */}
                                <div className="education-section m-4">
                                    <strong>Education</strong>
                                    {selectedReference.education ? selectedReference.education.map(edu => (
                                        <div key={edu.id} className="flex justify-between items-center bg-gray-200 hover:bg-gray-300 m-2 p-2 rounded shadow">
                                            {this.state.editingEducationId === edu.id ? (
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter education entry"
                                                        value={editingFieldValues[`entry-${edu.id}`] || ""}
                                                        onChange={(e) => this.handleInputChange(e, `entry-${edu.id}`)}
                                                        className="w-full p-2 mb-2 rounded border border-gray-300"
                                                        autoFocus
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Enter link"
                                                        value={editingFieldValues[`link-${edu.id}`] || ""}
                                                        onChange={(e) => this.handleInputChange(e, `link-${edu.id}`)}
                                                        className="w-full p-2 mb-2 rounded border border-gray-300"
                                                    />
                                                    <button
                                                        onClick={() => this.handleSaveField(edu.id, 'editingEducationId')}
                                                        className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2"
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            ) : (
                                                <div onDoubleClick={() => this.handleDoubleClick(edu.id, 'editingEducationId', { entry: edu.entry, link: edu.link })}>
                                                    <p className="text-black mb-1">{edu.entry}</p>
                                                </div>
                                            )}
                                            <button className="text-gray-800 font-semibold" onClick={() => this.handleDeleteEducation(selectedReference.id, edu.id)}>
                                                X
                                            </button>
                                        </div>
                                    )) : <></>}
                                    <button onClick={this.handleAddEducation} className="bg-sky-500 hover:bg-sky-600 text-white p-2 rounded shadow w-full mt-2">
                                        Add Education
                                    </button>
                                </div>

                            </>
                        )}
                    </div>
                </div>
                <ToastContainer />
            </div>
        );
    }
}

export default Referrences;
