import React from 'react';
import { useNavigation } from '../../utilities/NavigationContext';
import Recorder from 'recorder-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { textToPlan, textToReview } from '../../utilities/CDMAI';


import Planning from '../SharedComponents/Planning';
import Review from '../SharedComponents/Review';


function generateId() {
    return Math.random().toString(36).substr(2, 9);
}

const regenerateIds = (problems) => {
    if (!Array.isArray(problems)) {
        return []; // Return an empty array or handle the error as needed
    }

    return problems.map(problem => ({
        ...problem,
        id: generateId(),
        priority : "Low",
        collapsed: true,
        goal: Array.isArray(problem.goal) ? problem.goal.map(g => ({
            ...g,
            id: generateId(),
        })) : [], // Fallback to an empty array if goal is not present or not an array
        plan: Array.isArray(problem.plan) ? problem.plan.map(p => ({
            ...p,
            id: generateId(),
        })) : [], // Fallback to an empty array if plan is not present or not an array
    }));
};

// Function to add an ID to each object in the array of Review
function addReviewId(reviews) {
    return reviews.map(review => ({
      ...review,
      id: generateId()
    }));
}


function generateRandomDate(start, end) {
    const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    return date.toISOString().split('T')[0]; // Format as yyyy-mm-dd
}

function addFieldsToProblems(problems) {
    const date = new Date();
    const formattedDate = date.toISOString().split('T')[0];

    // Ensure problems is an array
    if (problems.length > 0) {
        return problems.map(problem => ({
        ...problem,
        dateAdded: formattedDate,
        id: generateId(),
        priority: "Low",
        collapsed: true,
        review:  (problem.review || []).map(g => ({
            ...g,
            id: generateId()
        })),
        journey: (problem.journey || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        goal: (problem.goal || []).map(g => ({
            ...g,
            id: generateId(),
            dateAdded: formattedDate
        })),
        plan: (problem.plan || []).map(p => ({
            ...p,
            id: generateId(),
            dateAdded: formattedDate
        }))
        }));
    }
  
}


class CDM extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contacts: [],
            referrences: [],
            formData: this.props.patientData,
            adminData: this.props.adminData,
            isEditing: false,
            //AI section
            isLoading: false,
            //active tab
            activeTab: 'planning',
           

        };

        // Initialize the Recorder instance
        this.recorder = new Recorder(new AudioContext(), {
            format: 'wav',
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.patientData !== this.props.patientData) {
            this.setState({ formData: this.props.patientData });
        }
        if (prevProps.adminData !== this.props.adminData) {
            this.setState({ amindData: this.props.adminData });
        }

    }
    

    updatePatientData = () => {
        this.props.updatePatientData(this.state.formData)
    };

    // Function to switch tabs
    switchTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    //Edit form functions
    handleToggleEdit = () => {
        this.setState((prevState) => ({
            isEditing: !prevState.isEditing
        }));
    };

    handleInputChange = (key, value) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: {
                    ...prevState.formData.planning,
                    [key]: value
                }
            }
        }));
    };
    

    /////  AI SECTION

    // After receiving transcription from Voice
    AITranscription = async (text, toastId) => {
       
            // organising Plan
            toast.update(toastId, {
                render: 'Organising Plan..',
                autoClose: false,
                hideProgressBar: false
            });

            var formData = this.state.formData;
            var adminData = this.state.adminData;
            var referrences = adminData.referrences;
            var respond2 = await textToPlan(text, formData.planning, referrences);
            var plan = addFieldsToProblems(respond2);
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    planning: plan
                },
            }), this.updatePatientData);
    
            // writing review
            toast.update(toastId, {
                render: 'Writing Review...',
                autoClose: false,
                hideProgressBar: false
            });
            var respond3 = await textToReview(text, formData.cdmreviews);
            var updateReviews = addReviewId(respond3)
      
            this.setState((prevState) => ({
                formData: {
                    ...prevState.formData,
                    cdmreviews: updateReviews,
                }
            }), this.updatePatientData); 
    
    
            // all done
            toast.update(toastId, {
                render: 'All task done!',
                type: toast.TYPE.SUCCESS,
                autoClose: 1000,  
                hideProgressBar: false
            });
    };


    ////// UPDATE FROM CHILD COMPONENT 

    // Function to update problems from child component
    handleProblemsUpdate = (updatedProblems) => {
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                planning: updatedProblems
            },
        }), this.updatePatientData);
    };

    handleReviewUpdate = (updatedReviews) => {
        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            mhreviews: updatedReviews,
          },
        }), this.updatePatientData);
    };
    
    // update message
    updateMessage = (message) => {
        this.setState({message: message})
    }

    render() {

        const { formData, activeTab } = this.state;

        return (
            <div  className="col-span-6 grid-rows-4 xs:grid-cols-1 md:grid-cols-1 lg:grid-cols-3" style={{ gridTemplateRows: ' minmax(12px, auto) minmax(12px, auto) minmax(320px, auto)' }}>
              
                <div className='text-center col-span-6 text-3xl font-bold py-4 text-sky-900'>Chronic Disease Management Plan</div>
                <div className="tabs col-span-6 my-4 flex justify-center">
                    <div className='bg-slate-100 rounded w-70'>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'planning' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('planning')}
                    >
                        Planning
                    </button>
                    <button
                    className={`p-2 m-2 shadow rounded w-32 hover:bg-sky-200 ${activeTab === 'review' ? 'bg-sky-300' : 'bg-slate-200'}`}
                    onClick={() => this.switchTab('review')}
                    >
                        Review
                    </button>
                    </div>
                </div>
      
                
                <div className='tabContent col-span-6'>
   
                    {activeTab === 'planning' && (
                    <Planning problems={formData.planning} onProblemsUpdate={this.handleProblemsUpdate}  onVoiceInput={this.AITranscription} onPaste={this.AITranscription}  category="Chronic Disease"/>
                    )}
                    {activeTab === 'review' && formData.cdmreviews && (
                    <Review reviews={formData.cdmreviews} onReviewUpdate={this.handleReviewUpdate}  onVoiceInput={this.AITranscription}  />
                    )}
                
                </div>
                <ToastContainer />
                
            </div>
        );
    }

}

export default CDM;
